.twitter-post-img-wrp {
  width: 100%;
  height: 300px;
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  margin-bottom: 12px;
  background-color: #000;
}
.twitter-post-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  opacity: 0.95;
}
.twitter-post-img-wrp:hover .twitter-post-img {
  opacity: 1;
  transform: scale(1.02);
}
.twitter-post-playbtn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  color: rgba(255, 255, 255, 0.8);
  font-size: 5rem;
  cursor: pointer;
  padding: 0;
  text-decoration: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.twitter-post-playbtn:hover {
  color: rgba(255, 255, 255, 1);
}
.twitter-post-img-date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0 4px;
  font-size: 14px;
  font-weight: 500;
  color: #536471;
  text-align: center;
}
.twitter-post-img-date p {
  margin: 0;
  text-align: center;
}
.twitter-post-btn {
  border: none;
  /* background-color: #fff; */
  color: black;
  background-color: lightgrey;
  font-size: 14px;
  border-radius: 5px;
}
.twitter-post-btn:hover {
  border: none;
  background-color: #414141;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
}
.date, .twitter-label {
  display: block;
  color: #fff;
  text-align: center;
  margin: 0;
  font-weight: 500;
  line-height: 1.1;
}

.twitter-label {
  font-size: 18px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.date {
  font-size: 14px;
  opacity: 0.8;
  margin-top: -1px;
}
@media screen and (max-width: 600px){
  .twitter-post-img-wrp {
    height: 300px;
}

}