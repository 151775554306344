.analytics-tables {
	max-width: 90%;
	margin-top: 10px;
	font-size: 1rem;
}

/* Tabs and date selector positioning */
.analytics-tabs-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.tabs-nav-section {
    width: 100%;
}

.tabs-content-section {
    width: 100%;
    /* padding-top: 15px; */
}

.analytics-tabs {
    width: 100%;
    margin-bottom: 0;
}

/* Left-align the tabs */
.analytics-tabs .ant-tabs-nav {
    margin-bottom: 0 !important;
}

.analytics-tabs .ant-tabs-nav .ant-tabs-nav-list {
    justify-content: flex-start !important;
    width: 100%;
}

/* Adjust tab styling for a more minimal look */
.analytics-tabs .ant-tabs-tab {
    padding: 8px 0 !important;
    margin: 0 24px 0 0 !important;
    font-weight: 400;
    font-size: 16px;
}

/* Make tabs look like the screenshot */
.analytics-tabs.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    background: transparent !important;
    border: none !important;
    border-bottom: 3px solid transparent !important;
    border-radius: 0 !important;
    margin-right: 24px !important;
    transition: all 0.2s;
}

.analytics-tabs.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
    border-bottom: 3px solid black !important;
    font-weight: 500;
}

/* Remove the tab card border and outline */
.analytics-tabs .ant-tabs-nav-wrap {
    padding-left: 0 !important;
}

.analytics-tabs .ant-tabs-nav::before {
    border-bottom: none !important;
}

/* Special wrapper for the date selector to position it properly */
.tab-date-selector-wrapper {
    width: 100%;
    /* padding: 15px 0 5px 0;
    margin-top: 5px; */
}

/* Position date selector below tabs but above content */
.analytics-tabs-container .date-scroll.date-selector {
    width: 100%;
    margin: 0;
    padding: 0;
    justify-content: flex-start;
}

/* Ensure no layout shift when changing tabs */
#analytics-row {
    display: flex;
    flex-direction: column;
}

/* Make tab label text align left within tab */
.modal-title {
    text-align: left;
}

.analytics-list {
	font-size: 1rem;
	line-height: normal;
	margin-bottom: 5%;
	margin-left: -12px;
	margin-top: 18px;
}
.analytics-postClicks-thumbnail-div {
	width: 100%;
}
.analytics-postClicks-thumbnail {
	/* max-width: 50%; */
	max-width: 25%;
    vertical-align: unset;
}

.search-form {
	font-size: 20px;
	margin: 25px 0 15px 0;
}

@media only screen and (max-width: 650px) {
	.tool-tip{
		font-size: 0.6rem;
		/* width:80vw; */
		word-wrap: break-word;
	}
}

/*  */

/* Smooth Chart Styles */
.smooth-chart-container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-bottom: 24px;
}

/* Make sure the chart lines appear smooth */
.ag-chart-wrapper path.ag-line-chart-path {
  stroke-width: 3px !important;
  transition: all 0.3s ease;
  stroke-linejoin: round !important;
  stroke-linecap: round !important;
  vector-effect: non-scaling-stroke !important;
  fill: none !important;
  shape-rendering: geometricPrecision !important;
}

/* Add a subtle gradient effect to the lines */
.ag-chart-wrapper path.ag-line-chart-path[stroke="#4F46E5"] {
  filter: drop-shadow(0 0 2px rgba(79, 70, 229, 0.2));
}

.ag-chart-wrapper path.ag-line-chart-path[stroke="#10B981"] {
  filter: drop-shadow(0 0 2px rgba(16, 185, 129, 0.2));
}

/* Style the grid lines to be subtle */
.ag-chart-wrapper line.ag-chart-grid-line {
  stroke-dasharray: 3, 3;
  stroke-opacity: 0.3;
}

/* Style the axis labels */
.ag-chart-wrapper text.ag-chart-axis-label {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  fill: #6B7280;
}

/* Style the legend */
.ag-chart-wrapper .ag-chart-legend-item text {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  fill: #4B5563;
}

/* Add hover effects to the chart */
.ag-chart-wrapper:hover path.ag-line-chart-path {
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
}

/* Style the tooltip */
.ag-chart-tooltip {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 8px 12px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  color: #4B5563;
  z-index: 100;
}

/* Responsive adjustments */
@media screen and (max-width: 767px) {
  .smooth-chart-container {
    padding: 16px;
  }
  
  .ag-chart-wrapper path.ag-line-chart-path {
    stroke-width: 2px !important;
  }
}
/*  */

/* Modern Analytics Styling */

/* Main container */
.analytics-section {
  margin: 20px 0;
}

/* Stats summary styling */
.stats-summary {
  display: flex;
  gap: 20px;
  margin-bottom: 24px;
  flex-wrap: wrap;
}

.stats-card {
  background-color: #f5f5f7;
  border-radius: 12px;
  padding: 20px;
  min-width: 180px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.03);
}

.stats-title {
  font-size: 14px;
  font-weight: 500;
  color: #6B7280;
  margin: 0 0 8px 0;
}

.stats-value {
  font-size: 28px;
  font-weight: 600;
  color: #212121;
}

/* Chart container */
.chart-container {
  background-color: white;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.03);
}

/* Custom tooltip */
.custom-tooltip {
  background-color: white;
  border-radius: 8px;
  padding: 10px 14px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border: none;
}

.tooltip-title {
  font-weight: 600;
  font-size: 14px;
  color: #212121;
  margin: 0 0 5px 0;
}

.tooltip-value {
  font-size: 13px;
  color: #555;
  margin: 0;
}

.tooltip-label {
  color: #6B7280;
  margin-right: 4px;
}

/* Modern table styling */
.analytics-modern-table {
  border-radius: 12px;
  overflow: hidden;
}

.analytics-modern-table .ant-table {
  background: transparent;
}

.analytics-modern-table .ant-table-thead > tr > th {
  background-color: #f5f5f7;
  color: #6B7280;
  font-weight: 500;
  font-size: 14px;
  padding: 14px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.analytics-modern-table .ant-table-tbody > tr > td {
  padding: 16px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

.analytics-table-row:hover > td {
  background-color: #f9f9fa !important;
}

/* Platform column */
.platform-column {
  display: flex;
  align-items: center;
  gap: 12px;
}

.platform-icon {
  justify-content: center;
  background: linear-gradient(45deg, #2446f0, #6e78ff);
  width: 34px;
  height: 34px;
  border-radius: 50%;
  box-shadow: 0 4px 20px rgba(36, 70, 240, 0.4);
  padding: 2%;
  display: flex;
  align-items: center;
  color: white;
  font-weight: 600;
  border-radius: 50%;
  font-size: 16px;
}

.platform-link {
  color: #212121;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.2s;
}

.platform-link:hover {
  color: #42b0ba;
}

/* Clicks value */
.clicks-value {
  font-weight: 600;
  font-size: 15px;
  color: #212121;
}
