/* 
 * OnboardingPage.css
 * Modern, clean styling for the onboarding process
 * Scoped to the onboarding-page class to avoid affecting other components
 */

.onboarding-page {
  min-height: 100vh;
  background: linear-gradient(to bottom right, #0f1013, #1e2028);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

/* Card styling updates */
.onboarding-page .instagram-card {
  width: 400px;
  background-color: rgba(33, 34, 38, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 32px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.onboarding-page .instagram-card:hover {
  /* Remove transform and box-shadow changes */
}

/* Header styling */
.onboarding-page .card-header {
  text-align: center;
  margin-bottom: 28px;
}

.onboarding-page .instagram-icon-wrapper {
  display: flex;
  justify-content: center;
  background: linear-gradient(45deg, #2446f0, #6e78ff);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin: 0 auto 24px;
  align-items: center;
  box-shadow: 0 4px 20px rgba(36, 70, 240, 0.4);
}

.onboarding-page .instagram-icon {
  width: 30px;
  height: 30px;
  color: white;
}

.onboarding-page .card-title {
  font-size: 24px;
  font-weight: 700;
  color: white;
  margin-bottom: 10px;
  letter-spacing: -0.01em;
}

.onboarding-page .card-description {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 24px;
}

/* Input styling */
.onboarding-page .input-wrapper {
  position: relative;
  margin-bottom: 24px;
}

.onboarding-page .input-wrapper.error .instagram-input {
  border-color: #ef4444 !important;
}

.onboarding-page .input-prefix {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.6);
  font-size: 18px;
  z-index: 2;
}

/* Special styling for URL prefix in username field */
.onboarding-page .url-prefix {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.7);
  font-size: 15px;
  font-weight: 400;
  pointer-events: none;
  white-space: nowrap;
  z-index: 2;
  border-right: 1px solid rgba(255, 255, 255, 0.25);
  padding-right: 8px;
}

/* Username input with URL prefix */
.onboarding-page .username-input {
  padding: 14px 16px !important;
  padding-left: 162px !important; /* Further increased padding for better separation */
  font-weight: 400;
  caret-color: white;
  background-color: rgba(20, 21, 25, 0.5) !important;
  border: 1px solid rgba(60, 63, 71, 0.5) !important;
  border-radius: 8px;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.9);
}

.onboarding-page .username-input::placeholder {
  font-weight: 400;
  font-style: italic;
  color: rgba(255, 255, 255, 0.45);
}

.onboarding-page .instagram-input {
  width: 100%;
  height: 48px;
  padding: 14px 16px;
  padding-left: 48px !important; /* Increased left padding to avoid icon collision */
  background-color: rgba(20, 21, 25, 0.5);
  color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(80, 82, 90, 0.4);
  border-radius: 8px;
  font-size: 15px;
  transition: border-color 0.2s ease, background-color 0.2s ease;
  box-sizing: border-box;
}

.onboarding-page .instagram-input:focus {
  outline: none;
  border-color: #2446f0;
  background-color: rgba(20, 21, 25, 0.6);
  box-shadow: 0 0 0 2px rgba(36, 70, 240, 0.2);
}

.onboarding-page .instagram-input::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

/* Button styling */
.onboarding-page .next-button {
  width: 100%;
  background: #4f56e3;
  color: #ffffff;
  padding: 14px 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-weight: 500;
  font-size: 16px;
  margin-top: 8px;
}

.onboarding-page .next-button:hover {
  background: #4750d9;
}

.onboarding-page .next-button:active {
  background: #424ac8;
}

.onboarding-page .next-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Back button styling */
.onboarding-page .back-button {
  width: 100%;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.7);
  padding: 14px 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.15);
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.02em;
  margin-top: 24px;
}

.onboarding-page .back-button:hover {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.25);
  transform: none;
}

.onboarding-page .back-button:active {
  background-color: rgba(255, 255, 255, 0.08);
  transform: none;
}

.onboarding-page .action-buttons {
  display: flex;
  gap: 16px;
  margin-top: 32px;
}

.onboarding-page .action-buttons .next-button,
.onboarding-page .action-buttons .back-button {
  margin-top: 0;
}

.onboarding-page .arrow-icon {
  margin-left: 8px;
  width: 18px;
  height: 18px;
}

/* Error message styling */
.onboarding-page .error-message {
  color: #ff4d4f;
  margin-bottom: 16px;
  font-size: 14px;
  background-color: rgba(255, 77, 79, 0.1);
  padding: 8px 12px;
  border-radius: 6px;
  border-left: 3px solid #ff4d4f;
}

/* Platform card styling */
.onboarding-page .platform-card {
  background-color: rgba(39, 40, 43, 0.8);
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 16px;
  border: 1px solid rgba(255, 255, 255, 0.08);
}

.onboarding-page .platform-card:hover {
  /* Remove transform and box-shadow changes */
}

/* Platform styling */
.onboarding-page .platform-header {
  padding: 14px 16px;
  background-color: rgba(51, 51, 51, 0.7);
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: white;
  position: relative;
}

.onboarding-page .platform-header:hover {
  background-color: rgba(51, 51, 51, 0.7);
}

.onboarding-page .platform-header .platform-icon {
  font-size: 20px;
  min-width: 20px;
  margin-right: 12px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: 2%;

    justify-content: center;
    background: linear-gradient(45deg, #2446f0, #6e78ff);
    width: 34px;
    height: 34px;
    border-radius: 50%;
    box-shadow: 0 4px 20px rgba(36, 70, 240, 0.4);
}

.onboarding-page .platform-icon {
  width: 30px;
  height: 30px;
  color: white;
}

.onboarding-page .platform-name {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.01em;
  margin-right: 16px;
  flex-shrink: 0;
}

.onboarding-page .platform-verified-badge {
  color: #3897f0; /* Instagram's verified badge blue color */
  font-size: 18px; /* Slightly increased size for better visibility */
  margin-right: 8px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.onboarding-page .platform-username {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  max-width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 8px;
}

.onboarding-page .dropdown-icon {
  margin-left: auto;
  font-size: 24px;
  transition: transform 0.2s ease;
  flex-shrink: 0;
}

/* Loading animation */
.onboarding-page .animate-spin {
  animation: spin 1s linear infinite;
  font-size: 1.4rem;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Loading state styling */
.onboarding-page .loading-state {
  text-align: center;
  color: #fff;
  padding: 24px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
}

.onboarding-page .loading-state::before {
  content: "";
  width: 40px;
  height: 40px;
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-top-color: #2446f0;
  border-radius: 50%;
  animation: spinner 1s linear infinite;
  display: block;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .onboarding-page .instagram-card {
    width: 100%;
    padding: 24px;
  }
  
  .onboarding-page .card-title {
    font-size: 22px;
  }
  
  .onboarding-page .platform-header {
    width: 100%;
  }
}

/* Password toggle button */
.onboarding-page .password-toggle-button {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  color: rgba(255, 255, 255, 0.5);
  z-index: 2;
}

.onboarding-page .password-toggle-button:hover {
  color: rgba(255, 255, 255, 0.7);
}

/* Custom styling for username field */
.onboarding-page .custom-username-field {
  position: relative;
  border-radius: 8px;
  background-color: rgba(20, 21, 25, 0.5);
  border: 1px solid rgba(60, 63, 71, 0.5);
  display: flex;
  align-items: center;
  overflow: hidden;
}

.onboarding-page .custom-username-field.error {
  border-color: #ef4444;
}

.onboarding-page .domain-prefix {
  color: rgba(255, 255, 255, 0.7);
  font-size: 15px;
  font-weight: 400;
  padding-left: 16px;
  white-space: nowrap;
  margin-right: 4px;
  min-width: 142px;
}

/* Username input with domain prefix */
.onboarding-page .custom-username-field .username-input {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  padding: 14px 12px !important;
  padding-left: 2px !important;
  flex-grow: 1;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
}

.onboarding-page .custom-username-field .username-input:focus {
  outline: none;
  box-shadow: none;
}
