.editable-text {
    position: relative;
    padding: 4px 8px;
    border-radius: 4px;
    transition: background-color 0.2s ease;
    margin: 4px 0;
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
  
  .editable-text span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    line-height: 1.2;
    max-height: 2.4em;
    word-wrap: break-word;
    white-space: normal;
  }
  
  .editable-text:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .editable-text::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #ddd;
    opacity: 0;
    transition: opacity 0.2s ease;
  }
  
  .editable-text:hover::after {
    opacity: 1;
  }
  
  .editable-text-input {
    border: none;
    border-bottom: 1px solid #ccc;
    outline: none;
    background-color: transparent;
    width: 100%;
    padding: 4px 0;
    margin-bottom: 10px;
  }
  
  .editable-text-input:focus {
    border-bottom-color: #404040;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .loading-indicator {
    margin-left: 8px;
    display: inline-flex;
    align-items: center;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .animate-spin {
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  } 