.account-social-content-connect {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin: 30px 0 30px 0;
	border-radius: 8px;
	overflow: hidden;
	background-color: #fcfcfc;
	border: solid #e8e8e8 1px;
}

.account-social-content-connect-row-platform {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 15px;
	background-color: white;
	border-radius: 8px;
	border: 1px solid rgba(0, 0, 0, 0.05);
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.03);
	transition: all 0.2s ease;
}

.account-social-content-connect-row-platform:hover {
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
	transform: translateY(-1px);
}

.account-social-content-connect-row-platform .platform-switch {
	margin-left: 0%;
	display: flex;
	gap: 15px;
	margin-right: 15%;
}

.account-social-content-connect-row {
	display: flex;
	align-items: center;
	/* padding: 15px 0; */
	padding: 5px;
	cursor: pointer;
}

.account-social-content-connect-row-cont {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 150px;
	gap: 12px;
}

.account-social-content-connect-row-cont p {
	margin: 0;
	font-weight: 500;
}

.account-social-content-connect-status {
	border: balck;
	color: black;
	border: solid black 1px;
	background-color: white;
	border-radius: 5px;
	padding: 3px 8px;
	display: flex;
	gap: 3px;
	font-size: 12px;
	align-items: center;
	justify-content: center;
}

.account-social-content-connect-status.active {
	border: solid rgb(177, 240, 199) 1px;
	background-color: rgb(177, 240, 199);
	color: rgb(54, 146, 82);
	height: 20px;
	width: 20px;
	padding: 0;
	border-radius: 100%;
	cursor: default;
}

.account-social-content-channelid-text {
	color: #0066cc;
	text-decoration: underline;
	margin: 20px 0 0 0;
	font-size: 0.9rem;
	cursor: pointer;
}

.account-social-content-channelid-ins {
	margin: 20px 0 20px 0;
}

.account-social-content-channelid-link {
	color: #0066cc;
	text-decoration: underline;
	margin: 0;
	cursor: pointer;
	background-color: transparent;
}

.account-social-content-channelid-link:hover {
	color: #0066cc;
	text-decoration: underline;
	background-color: transparent;
}

.spin-integration {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100px;
}

.alert-margin {
	margin: 10px 0;
}

.mt-5 {
	margin-top: 5%;
}

.chevron-right {
	margin: 0 6px 0 0;
}

@media screen and (max-width: 768px) {
	.platforms-grid {
		grid-template-columns: 1fr;
	}

	.account-social-content-connect {
		width: 100%;
		margin: 30px 0;
	}

	.account-social-content-connect-row-platform {
		justify-content: space-between;
		width: 100%;
	}

	.account-social-content-connect-row-platform .platform-switch {
		margin-left: 0;
		margin-right: 0;
	}
}

@media screen and (max-width: 600px) {
	.account-social-content-connect {
		width: 100%;
		margin: 30px 0;
	}

	.account-social-content-connect-row-platform {
		justify-content: flex-start;
		width: 65%;
		/* margin-left: 2%; */
	}

	.account-social-content-connect-row-platform .platform-switch {
		margin-left: 25%;
		margin-right: 0%;
	}
}

.creator-profile-social-links .account-social-content-connect {
	margin: 0;
	border: none;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	background: transparent;
	gap: 5px;
	flex-wrap: wrap;
}

.creator-profile-social-links .account-social-content-connect .SocialLinksIcon {
	display: flex;
	justify-content: center;
	text-align: center;
	padding: 10px 0;
	padding-top: 13px;
    padding-bottom: 8px;
	grid-gap: 5px;
	grid-row-gap: 10%;
	flex-wrap: wrap;
	padding-left: 0;
}

.creator-profile-social-links .account-social-content-connect .SocialLinksIcon .active-social-links {
	width: 35px;
	height: 35px;
	font-size: 1rem;
}

.creator-profile-social-links .add-button {
	padding: 0;
	/* padding-top: 2px; */
	border: none;
	border-radius: 30px;
	height: 42px;
	width: 42px;
	/* justify-content: center; */
	color: black;
	cursor: pointer;
}

/* Platform Integration styles */
.platform-integration-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 30px;
	margin: 30px 0;
}

/* Layout for platforms */
.platforms-layout {
	display: flex;
	flex-direction: column;
	gap: 30px;
	width: 100%;
}

.platforms-column {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
}

/* Two-column grid for desktop view */
@media screen and (min-width: 961px) {
	.platforms-grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 20px;
	}
	
	/* Ensure platform cards have consistent height */
	.platform-integration-container .platform-card {
		height: 100%;
		min-height: 80px;
		margin-bottom: 0;
	}
}

/* Single column for all other screen sizes */
@media screen and (max-width: 960px) {
	.platforms-grid {
		display: flex;
		flex-direction: column;
		gap: 15px;
	}
	
	.platform-card {
		margin-bottom: 15px;
	}
}

.platform-section {
	width: 100%;
	background-color: #f5f5f7;
	border-radius: 12px;
	border: 1px solid rgba(0, 0, 0, 0.03);
	box-shadow: none;
	padding: 20px;
	overflow: visible;
	height: auto;
	margin-bottom: 20px;
	box-sizing: border-box;
}

.platform-section-title {
	display: flex;
	align-items: center;
	font-size: 1rem;
	font-weight: 600;
	margin-bottom: 20px;
	color: #333;
}

.section-title-bar {
	width: 4px;
	height: 1rem;
	background-color: #000;
	margin-right: 10px;
	border-radius: 0;
}

/* Platform cards with improved layout */
.platform-integration-container .platform-card {
	display: flex;
	background-color: #262626;
	border-radius: 8px;
	overflow: hidden;
	margin-bottom: 12px;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	/* background-color: #1e1e1e; */
	border-radius: 10px;
	box-shadow: none;
	transition: all 0.2s ease;
	width: 100%;
	
	margin-bottom: 15px;
	box-sizing: border-box;

	
}

/* Platform info */
.platform-info {
	display: flex;
	flex-direction: column;
	width: auto;
	min-width: 120px;
}

.platform-name {
	font-size: 1.1rem;
	font-weight: 500;
	margin: 0;
	color: #fff;
}

.platform-name.connected {
	font-weight: 600;
}

.platform-username {
	font-size: 0.85rem;
	color: rgba(255, 255, 255, 0.7);
	margin: 4px 0 0 0;
}

/* Status elements container */
.platform-status-container {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-right: 15px;
}

/* Platform actions */
.platform-actions {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
}

/* Connected status badge */
.connected-badge {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background-color: #3cb371;
	border-radius: 24px;
	padding: 8px 16px;
	border: none;
	width: 110px;
	text-align: center;
}

.connected-text {
	font-size: 0.9rem;
	color: white;
	font-weight: 500;
}

/* Connect button */
.connect-button {
	background-color: #ffffff;
	border: none;
	border-radius: 4px;
	padding: 8px 16px;
	font-size: 0.9rem;
	font-weight: 500;
	color: #333;
	cursor: pointer;
	transition: all 0.2s ease;
	width: 110px;
	text-align: center;
}

.connect-button:hover {
	background-color: #f0f0f0;
}

/* Original toggle switch styling */
.ant-switch {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.ant-switch.ant-switch-checked {
  background-color: #3cb371 !important; /* Green for On state */
}

/* Remove all the custom overrides */
.ant-switch-handle,
.ant-switch-inner,
.platform-card .ant-switch {
  /* Reset to default Ant Design styling */
}

/* Lock icon styling */
.platform-lock {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.lock-icon {
	color: rgba(255, 255, 255, 0.5);
	font-size: 1rem;
}

/* Processing badge */
.status-badge.processing {
	background-color: #fff6e0;
	color: #c4930a;
	border: 1px solid rgba(251, 192, 45, 0.3);
	padding: 6px 12px;
	border-radius: 4px;
	font-size: 0.9rem;
}

.status-badge.processing p {
	margin: 0;
}

/* Tablet breakpoint - ensure horizontal layout preserved */
@media screen and (max-width: 960px) and (min-width: 768px) {
	.platform-card {
		padding: 16px;
	}
	
	.platform-section {
		padding: 16px;
	}
	
	.connected-badge, .connect-button {
		width: 100px;
	}
}

/* Mobile breakpoint - stack elements vertically */
@media screen and (max-width: 767px) {
	/* Keep horizontal layout but with adjusted spacing */
	.platform-card {
		padding: 16px;
		display: flex;
		flex-wrap: wrap;
	}
	
	.platform-info {
		flex: 1;
		margin-bottom: 0;
		min-width: 120px;
	}
	
	.platform-status-container {
		flex: 0 0 auto;
		margin-right: 15px;
	}

	.platform-integration-container .platform-card {
		justify-content: center;
	}
	
	.platform-actions {
		flex: 0 0 auto;
		width: 50px;
	}
}

/* Tiny mobile screens */
@media screen and (max-width: 400px) {
	.platform-card {
		padding: 12px;
	}
	
	.platform-status-container {
		width: 100%;
		margin-bottom: 10px;
		margin-right: 0;
	}
	
	.platform-actions {
		width: auto;
		margin-left: auto;
	}
	
	.platform-section {
		padding: 12px;
	}
}

/* Upgrade modal styling */
.upgrade-text {
	text-align: center;
	font-size: 16px;
	color: #333;
	margin: 20px 0;
}

.modal-cancel-btn {
	background-color: transparent;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	padding: 6px 15px;
	cursor: pointer;
	font-size: 14px;
	color: #333;
}

.modal-upgrade-btn {
	background-color: #000;
	border: none;
	border-radius: 4px;
	padding: 7px 16px;
	cursor: pointer;
	font-size: 14px;
	color: #fff;
	margin-left: 10px;
	font-weight: 500;
}

.modal-cancel-btn:hover {
	background-color: #f5f5f5;
}

.modal-upgrade-btn:hover {
	background-color: #333;
}

/* Styles for the platform cards and status elements */
.platform-info {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.platform-name-status {
	display: flex;
	align-items: center;
	gap: 10px;
}

.platform-name {
	font-size: 1.1rem;
	font-weight: 500;
	margin: 0;
	color: #fff;
}

.platform-name.connected {
	font-weight: 600;
}

.platform-username {
	font-size: 0.85rem;
	color: rgba(255, 255, 255, 0.7);
	margin: 4px 0 0 0;
}

.platform-lock {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.lock-icon {
	color: rgba(255, 255, 255, 0.5);
	font-size: 1rem;
}

.platform-actions {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
}

/* Status badges */
.status-badge {
	display: flex;
	align-items: center;
	gap: 4px;
	font-size: 0.8rem;
	padding: 4px 8px;
	border-radius: 4px;
	font-weight: 500;
}

.status-badge p {
	margin: 0;
	line-height: 1;
}

.status-badge.connect {
	background-color: #f5f5f7;
	color: #333;
	border: 1px solid rgba(0, 0, 0, 0.1);
	cursor: pointer;
}

.status-badge.connect:hover {
	background-color: #eaeaec;
}

.status-badge.connected {
	background-color: #e8f5e9;
	color: #2e7d32;
	border: 1px solid rgba(46, 125, 50, 0.1);
}

.check-icon {
	font-size: 1rem;
}

/* Adjust spacing for right column sections */
.platforms-layout .platforms-column:last-child {
	display: flex;
	flex-direction: column;
	gap: 20px;
	height: auto;
	align-self: flex-start;
}

/* Responsive adjustments for medium screens */
@media screen and (max-width: 900px) {
	.platform-card {
		padding: 16px;
	}
	
	.platform-info {
		flex: 1 0 100%;
		margin-bottom: 12px;
	}
	
	.platform-status-container {
		margin-right: 10px;
	}
}

/* Further adjustments for small screens */
@media screen and (max-width: 500px) {
	.platform-card {
		padding: 12px;
	}
	
	.connected-badge, .connect-button {
		width: 90px;
		padding: 6px 10px;
		font-size: 0.85rem;
	}
}

/* Specific fix for ~840px width where content was getting cut off */
@media screen and (max-width: 1200px) and (min-width: 769px) {
  .platform-card {
    flex-wrap: wrap;
    padding: 16px;
  }
  
  .platform-info {
    flex: 1 0 100%;
    margin-bottom: 12px;
  }
  
  .platform-status-container {
    margin-right: 12px;
    flex: 1;
    justify-content: center;
  }
  
  .platform-actions {
    width: auto;
    min-width: 40px;
  }
  
  .connected-badge, .connect-button {
    margin-left: 0;
  }
  
  .platform-section {
    padding: 16px;
  }
  
  .platforms-layout {
    gap: 20px;
  }
  
  .platforms-grid {
    gap: 16px;
  }
}

/* In this media query, adjust the spacing between elements */
@media screen and (max-width: 900px) {
	.platform-section {
		padding: 16px;
	}
	
	.platforms-layout {
		gap: 20px;
	}
	
	.platforms-grid {
		gap: 16px;
	}
	
	.platform-section-title {
		margin-bottom: 16px;
	}
}

/* Small mobile devices - stack elements */
@media screen and (max-width: 480px) {
	.platform-card {
		flex-direction: row;
		align-items: flex-start;
		padding: 16px;
	}
	
	.platform-info {
		margin-bottom: 15px;
		width: 100%;
	}
	
	.platform-status-container {
		margin-bottom: 15px;
		margin-right: 0;
		width: 100%;
		justify-content: center;
	}
	
	.platform-actions {
		width: 100%;
		justify-content: flex-start;
	}
}