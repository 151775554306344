.ant-modal-content {
  bottom: 0;
  height: auto;
  top: unset;
  color: black;
  border-radius: 1.5rem !important;
}

.newbutton {
  margin-right: auto;
  border: none;
  background-color: white;
  color: #848484;
}


.title {
  margin: 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: bold;

}

.title>h5 {
  font-weight: 700;
  display: block;
  text-align: center;
  color: black;
  margin-bottom: 4%;
  width: 100%;
  margin-top: 2.5%
}

h3 {
  font-weight: 800px !important
}

.ant-checkbox-wrapper {
  display: flex !important;
}

/* CenteredButton.css */
.centered-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Full viewport height */
}

.centered-button {
  width: 80px;
  /* Adjust the size as needed */
  height: 80px;
  /* Adjust the size as needed */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  /* Icon size */
  background-color: #007bff;
  /* Bootstrap primary color */
  border: none;
}

.centered-button:hover {
  background-color: #0056b3;
  /* Darker shade for hover effect */
}

.button-icon {
  justify-content: center;
  align-items: center;
  display: block;
  font-size: 3rem;
  /* Adjust icon size as needed */
  max-width: 45rem !important;
  width: 100%;
  cursor: pointer;
}

.preview {
  gap: .25px;
  background: #fff;
  border: 1px solid #41b0ba;
  border-radius: 1rem;
  box-shadow: 0 .125rem .75rem -.25rem rgba(10, 20, 26, .08), 0 0 0 0.1875rem #e0f4f5;
  color: #000;
  gap: 1.25rem;
  padding: calc(1rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all .15s ease-in-out;
  width: 100%;
  margin-bottom: 15px;
}

.preview .banner-wrapper {
  display: flex;
  /* align-items: center; */
  /* paddingTop: 15px; */
  padding-bottom: 10px;
}

.fieldGroup {
  align-items: flex-start;
  background: #fff;
  border: 1px solid #e6e7eb;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 100%;
}

.previewImage {
  display: flex;
  position: relative;
  width: 100%;
  max-height: 300px;
  object-fit: contain;
  border-radius: 1rem;
}

.previewImage img {
  width: 100%;
  margin: 0 auto;
  object-fit: cover;
  border-radius: 1rem;
  /* object-position: 0% 50%; */
  max-height: 300px;
}

.uploadDiv {
  display: block;
  margin: auto;
  text-align: center;
  width: 100%;
}

.previewHeading {
  /* align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center; */
  margin-right: auto;
  text-align: left;
  color: black;
  line-height: 1.3;
  padding-right: 0.5rem;
  margin-bottom: 0rem;
  font-weight: 700;
  font-size: 1.25rem;
  width: 65%;
  text-decoration: none !important;
}

.previewHeading p {
  margin-right: auto;
  color: black;
  line-height: 1.3;
  padding-right: 0.5rem;
  margin-bottom: 0rem;
  width: 100%;
  font-weight: 700;
  font-size: 1.25rem;
}

.ant-upload-list-item-name {
  word-break: break-word;
}

@media screen and (max-width: 400px) {
  .fieldGroup {
    padding: 1.5rem;
  }

  .imageUploader {
    display: block !important;
    justify-content: center;
    align-items: center;
  }

  .preview {
    display: block;
    justify-content: center !important;
    align-items: center;
  }

}

.fieldGroup input {
  background: #fff;
  border: 1px solid #c9ccd1;
  align-items: center;
  border-radius: 0.75rem;
  cursor: text;
  display: flex;
  overflow: hidden;
  padding-left: 1rem;
  position: relative;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.fieldGroup input:focus-within {
  border-color: #41b0ba;
}

.fieldGroup input:hover {
  border-color: #afdfe0;
}

.fieldGroup input:focus {
  border-color: #41b0ba;
  box-shadow: none;
}

.imageUploader {
  width: 100%;
  align-items: center;
  border: 1.5px dashed #c9ccd1;
  border-radius: 0.625rem;
  gap: 1rem;
  justify-content: center;
  padding: 1rem, 1.5rem, 1rem 1.25rem;
  display: block;
  padding-bottom: 2% !important;
}

.textContainerHeading {
  margin-top: 2%;
  color: #51555c;
  display: block;
  font-size: medium;
  width: 100%;
  white-space: break-spaces;
}

.uploadButton {
  display: block;
  margin: auto;
  text-align: center;
  width: 100%;
  height: auto;
  border: 1.5px dashed #c9ccd1;
  border-radius: 0.625rem;
  padding: 1rem, 1.5rem, 1rem 1.25rem;
  box-shadow: none !important;
}

.imageUploader button {
  align-items: center;
  background: #fff;
  border: 1px solid #e6e7eb;
  border-radius: 0.5rem;
  box-shadow: 0 1px .25rem 0 rgba(10, 20, 26, .08);
  color: #000;
  gap: 0.375rem;
  justify-content: center;
  padding: 0.375rem, 0.75rem;
  margin: 0 auto;
  white-space: nowrap;
  font-size: 0.875rem;
  font-weight: bold;
}

.ant-tabs-tab.ant-tabs-tab-active:active {
  border: none !important;
  border-bottom: 2px solid #41b0ba !important;
}

@media screen and (max-width:600px) {
  .previewImage {
    max-height: 200px;
  }
}

.edit-ant-btn {
  font-size: smaller;
  border: none;
  max-width: 250px;
  white-space: normal;
  word-break: break-word;
}

.card-grid-item {
  max-width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 4;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  overflow: hidden;
}

.card-grid-item div {
  padding: 16;
}

.card-grid-item h3 {
  margin-bottom: 8;
  font-weight: bold;
}

.card-grid-item img {
  width: 100%;
  height: 12rem;
  border-radius: 4px 4px 0 0;
}

.feed-tab-post {
  height: auto;
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  width: 100%;
  vertical-align: top;
}

.bold-label {
  font-weight: bold;
}

.editTitle {
  display: block;
  text-align: center;
  color: black;
  margin-bottom: 2%;
  margin-top: 4%;
}

.added-post {
  display: inline-block;
  margin-right: 10px;
  width: 140px;
  vertical-align: top;
}

.h-300px {
  height: 300px;
}

.modal-title {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 15px;
}

@media screen and (max-width: 425px) {
  .modal-title {
    display: flex;
    align-items: center;
  flex-direction: column;
  }
  .mr-16{
    margin-right: 16px;
  }
  .mb-15{
    margin-bottom: 15px;
  }
}

.modal-title button {
  margin-right: auto;
}

.errorMsg {
  /* color: red; */
  color: rgb(241, 98, 98)
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-4 {
  margin-top: 4%;
}

.marginR-auto {
  margin-right: auto;
}

.m-0 {
  margin: 0;
}

.mr-4 {
  margin-right: 4%;
}

.text-center {
  text-align: center;
}

.list-item p {
  margin: 0;
  text-align: left !important;
}

.list-item button {
  border: none;
  background: transparent;
  cursor: pointer;
}

.list-button {
  width: 100%;
  text-align: left;
  height: auto;
}

.image-upload-section {
  display: block;
  margin: auto;
  width: 100%;
  text-align: center;
}

.upload-text {
  color: #70747c;
  font-size: .75rem;
}

.mw-250 {
  max-width: 250px;
}

.h-5r {
  height: 5rem;
}

.margin-added-content {
  margin: 0 0 20px 0;
}

.tag-style {
  margin-top: 2%;
  margin-left: 2%;
  font-size: 0.8rem;
}

.pagination {
  margin: 10px;
  display: block;
  text-align: center;
}

.h-255 {
  height: 255px;
}

.h-250 {
  height: 250px;
}

.mt-300 {
  max-height: 300px;
}

.mt-7 {
  margin-top: 7.5%
}

.skeletonDiv {
  margin: 0 auto;
  margin-bottom: 2%;
  width: 100%;
  height: 160px;
  margin-right: 4%;
}

.skeletonStyle {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 15px;
  padding: 2%;
}

.addedContent {
  border: 1.5px dashed #c9ccd1;
  margin-bottom: 5%;
  padding: 2%;
  border-radius: 5px;
}

.addedContentTitle {
  font-weight: 500;
  color: grey;
}

.mb-2 {
  margin-bottom: 2%;
}

.post-image {
  width: 100%;
  margin: 0 auto;
  border-radius: 1rem;
}

.w-80 {
  width: 80%;
}

.post-width {
  flex: 0 0 auto;
  margin-right: 0px;
  width: 140px;
}

.archive-toggle-section {
  cursor: pointer;
  padding: 10px;
  background-color: #f5f4f4;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6%;
}

.archive-toggle-section-button {
  color: #007bff;
  margin-left: 10px;
  font-style: italic
}

.list-item-btn {
  width: 100%;
  text-align: left;
  display: block;
}

.list-btn-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-btn-text p {
  margin: 0;
}

.list-btn-text button {
  border: none;
  background: transparent;
  cursor: pointer;
}

.item-description {
  margin: 0;
  font-weight: bold;
  font-size: 12px;
}

.h-auto {
  height: auto;
}

.flex-direction {
  flex-direction: column;
}

.display-flex {
  display: flex;
}

.mw-350 {
  max-width: 350px;
}

.state-box {
  width: 140px;
  height: 250px;
  margin-top: 37px;
  border-radius: 1rem;
}

.clear-btn {
  display: flex;
  margin-top: 2%;
}

.mb-20 {
  margin-bottom: 20px;
}

.mr-auto {
  margin-right: auto;
}

.m-0 {
  margin: 0;
}

.item-task {
  border: none;
  background: transparent;
  cursor: pointer;
}

.item-disabled {
  width: 100%;
  text-align: left;
  height: auto;
}

.moadl-title {
  display: flex;
  align-items: center;
}

.item-disc {
  margin: 0;
  font-weight: bold;
  font-size: 12px;
}

.list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  white-space: break-spaces;
  text-align: center;
}

.mr-16 {
  margin-right: 16px;
}

.trending-input-box {
  width: 100%;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  margin-bottom: 15px;
}

.tranding-card {
  background-color: #f1f1f1;
}

.link-presentation {
  display: flex;
  gap: 20%;
  justify-content: center;
}

.links-view-icon {
  height: 38px;
  width: 38px;
}

.link-presentation span {
  padding: 4px;
}

.link-presentation span.active {
  border: 1px solid;
  padding: 4px;
  border-radius: 4px;
  color: #000;
}

.ant-message-notice-content {
  color: black !important;
  /* Change text color to black */
}

.ant-upload-list-item-error .ant-upload-list-item-name,
.ant-upload-list-item-error .ant-upload-icon .anticon {
  color: #000 !important;
}

.newsletter-media-carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* height: 350px; */
  text-wrap: auto;
  border-radius: 1rem;
  background-color: #1a1a16;
  color: #fff;
  padding: 0.5%;
}
.no-media-url{
  margin-bottom: 5px;
  /* background-color: black;
  color: white;
  border-radius: 1rem;
  border: 1px solid #e6e7eb; */
}

.component-text {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  cursor: grab;
}

.component-text:active {
  cursor: grabbing;
}

.outline-drag {
  font-size: 20px;
  color: #666;
}

/* Add to your page modal styling */
.add-to-page-modal .ant-modal-content {
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.add-to-page-modal .ant-modal-body {
  padding: 24px 32px 32px;
}

.modal-close-icon {
  font-size: 22px;
  color: #999;
  transition: color 0.2s;
}

.modal-close-icon:hover {
  color: #333;
}

.modal-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #111;
}

.modal-subtitle {
  color: #666;
  margin-bottom: 24px;
  font-size: 15px;
}

.components-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.components-section {
  margin-bottom: 8px;
}

.section-title {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin-bottom: 16px;
  padding-bottom: 8px;
  /* border-bottom: 1px solid #eee; */
}

.components-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}

.component-card {
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid #eee;
}

.component-card:hover {
  background-color: #f2f2f2;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.component-card.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.component-card-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.component-card h4 {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  color: #333;
}

.component-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #000;
  color: white;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 300;
  line-height: 1;
}

/* Media query for mobile devices */
@media (max-width: 576px) {
  .components-grid {
    grid-template-columns: 1fr;
  }
  
  .add-to-page-modal .ant-modal-body {
    padding: 20px;
  }
  
  .modal-title {
    font-size: 20px;
  }
}

.task-btn {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.task-icon {
  background-color: #333;
}

.component-card:hover .task-icon {
  background-color: #000;
}
