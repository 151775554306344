/* .line-template-card {
    background: white;
    border-radius: 12px;
    padding: 20px;
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  } */

/* .line-template-card-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
   */

.line-template-card {
  position: relative;
  /* border: 1px solid #e0e0e0; */
  min-height: 100px;
  background-color: white;
  background: white;
  width: 100%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
  min-height: 5.25rem;
  height: 100%;
  padding: 5px 0;
  text-align: center;
  display: flex;
}

.line-template-card-content {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  min-height: 5.25rem;
  height: 100%;
  padding: 10px 0;
  width: 100%;
}

/* Add padding-left only when image exists */
.has-image .line-template-card-content,
.has-image .input-section {
  padding-left: 64px;
}

.line-template-left-section {
  flex: 1;
  font-size: 16px;
  color: #000;
  padding: .5rem 1rem .5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 16px;
  word-break: break-word;
  overflow-wrap: break-word;
  position: relative;
  z-index: 2;
}

/* .line-template-left-section:hover, */
.line-template-card:hover {
  background-color: #d0d2d5;
  border-radius: 8px;
  overflow: hidden;
  font-weight: bolder;
}

.line-template-text-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.line-template-logo {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;
}

.line-template-logo-placeholder {
  width: 48px;
  height: 48px;
  background-color: #f5f5f5;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.2s;
}

.line-template-logo-placeholder:hover {
  background-color: #eeeeee;
}

.line-template-upload-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: #666;
}

.line-template-title-section {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 8px;
  width: calc(100% - 50px);
}

.line-template-title {
  font-size: 16px;
  color: #000;
  font-weight: bolder;
  overflow-wrap: break-word;
  /* word-break: break-all;         // Forces breaks at any character */
  white-space: pre-wrap; 
  max-width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: auto;
  text-align: center;
  margin: 0;
  padding: 0 10px;
}

.line-template-title.no-image {
  padding-left: 64px;
}

.has-image .line-template-title {
  padding-left: 0;
}

@media (max-width: 450px) {
  .line-template-title.no-image {
    padding-left: 35px;
  }
}

.line-template-text-center {
  margin: 0 auto;
  text-align: center;
  width: 100%;
  padding: 0 60px;
}

.has-image .line-template-text-center {
  padding: 0;
}

.line-template-title.title-placeholder {
  font-size: 16px;
  color: #aaa;
  font-style: italic;
  /* Optional: Makes the placeholder text look distinct */

  font-weight: 500;
  /* margin: 0 auto; */
}

.line-template-link-section {
  display: flex;
  align-items: center;
  gap: 8px;
  width: calc(100% - 50px);
}

.line-template-link {
  font-size: 14px;
  color: #888;
  margin: 0;
}


.line-template-button {
  padding: 4px;
  background: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.line-template-button:hover {
  background-color: #f5f5f5;
}

.line-template-close-button {
  width: 32px;
  height: 32px;
  padding: 4px;
  background: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
}

.line-template-close-button:hover {
  background-color: #f5f5f5;
}

.line-template-icon {
  color: #666;
}

.line-template-actions {
  position: absolute;
  top: 8px;
  right: 8px;
  display: grid;
  align-items: center;
  gap: 8px;
  z-index: 10;
}

.line-template-edit-button,
.line-template-save-button,
.line-template-delete-button {
  background: white;
  border: none;
  width: 26px !important;
  height: 26px !important;
  min-width: 26px !important;
  min-height: 26px !important;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  aspect-ratio: 1 / 1;
  /* Prevent Safari stretching */
  padding: 0 !important;
  /* Remove unwanted padding */
  margin: 0 !important;
  overflow: hidden;
  /* Ensures clean shape */
}

.line-template-edit-button:hover,
.line-template-save-button:hover,
.line-template-delete-button:hover {
  background: #f8f9fa;
}

.line-template-edit-button svg,
.line-template-save-button svg,
.line-template-delete-button svg {
  width: 16px;
  height: 18px;
  flex-shrink: 0;
  display: block !important;
  color: black !important;
}

.line-template-drag-icon {
  color: #6c757d;
  cursor: move;
}

.line-image-wrapper {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  z-index: 1;
}

.line-template-logo-placeholder {
  width: 64px;
  height: 64px;
  /* border: 2px dashed #d1d5db; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.line-template-title-input {
  font-size: 16px;
  color: #666;
  font-weight: 500;
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  /* width: calc(100% - 120px); */
  max-width: 400px;
  margin: 0 auto;
}

.line-template-link-input {
  font-size: 14px;
  color: #666;
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  /* width: calc(100% - 120px); */
  max-width: 400px;
  margin: 0 auto;
}

.has-image .line-template-title-input,
.has-image .line-template-link-input {
  /* width: calc(100% - 100px); */
}

.line-template-title,
.line-template-link {
  margin: 0;
  color: #333;
}

.line-template-delete-confirm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.line-template-delete-confirm-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 90%;
}

.line-template-delete-confirm-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.line-template-delete-confirm-cancel,
.line-template-delete-confirm-delete {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.line-template-delete-confirm-cancel {
  background: #f8f9fa;
  color: #6c757d;
}

.line-template-delete-confirm-delete {
  background: #dc3545;
  color: white;
}




.line-template-logo-container {
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 8px;
  overflow: hidden;
}

.line-template-logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.line-template-logo-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.line-template-logo-overlay-actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.line-template-overlay-icon {
  color: grey;
  background-color: white;
  border-radius: 50px;
  cursor: pointer;
  padding: 2px;
  transition: transform 0.2s;
}

.line-template-overlay-icon:hover {
  transform: scale(1.1);
}

.upload-area {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.line-template-logo-placeholder .ant-upload,
.line-template-logo-placeholder .ant-upload-wrapper {
  width: 100%;
  height: 100%;
}

.line-template-logo-overlay-actions .ant-upload,
.line-template-logo-overlay-actions .ant-upload-wrapper {
  width: auto;
  height: auto;
}

.line-template-save-button {
  min-width: 22px !important;
  min-height: 22px !important;
  width: 22px !important;
  height: 22px !important;
}

.line-title-center {
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 500px) {
  .line-template-card {
    min-height: auto;
    padding: 14px;
  }

  .has-image .line-template-card-content {
    padding-left: 70px;
  }

  .line-template-card-content {
    min-height: 4rem;
  }
  
  .line-image-wrapper {
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
  }

  .line-template-logo-placeholder {
    width: 48px;
    height: 48px;
  }

  .line-template-title-section{
    width: calc(100% - 30px);
  }

  .line-template-link-section{
    width: calc(100% - 30px);
  }
  
  .line-template-title {
    font-size: 14px;
    padding: 0 5px;
  }

  .line-template-title-input,
  .line-template-link-input {
    /* width: calc(100% - 80px); */
    /* max-width: none; */
  }

  .has-image .line-template-title-input,
  .has-image .line-template-link-input {
    /* width: calc(100% - 60px); */
  }

  .line-template-text-center {
    padding: 0 40px;
  }

  .has-image .line-template-text-center {
    padding: 0;
  }

  .line-template-left-section {
    padding: .25rem .5rem;
  }

  .line-title-center {
    margin: 0;
    padding: 0;
  }
  .line-template-text-section{
    align-items: flex-start !important;
  }
  .line-template-actions{
    right: -4px;
  }
}


.dragging-line {
  opacity: 0.5;
}

.drag-over-line {
  border: 2px dashed #ccc;
}