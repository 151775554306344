.stat-box-wrapper {
	display: flex;
	flex-direction: column;
	background-color: #f5f5f7;
	border-radius: 16px;
	padding: 1.5rem;
	width: 100%;
	box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
	border: 1px solid rgba(0, 0, 0, 0.06);
}

/* Styling for data table variant */
.stat-box-table {
	display: flex;
	flex-direction: column;
	background-color: #f5f5f7;
	border-radius: 16px;
	padding: 1.25rem 1.5rem;
	width: 100%;
	box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
	border: 1px solid rgba(0, 0, 0, 0.06);
}

.stat-box-table-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1.25rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.stat-box-table-title {
	font-size: 1.125rem;
	font-weight: 600;
	color: #374151;
	margin: 0;
}

.stat-box-table-info svg {
	color: #6B7280;
	font-size: 1.125rem;
	cursor: pointer;
	transition: color 0.2s ease;
}

.stat-box-table-info svg:hover {
	color: #2446f0;
}

.stat-box-table-row {
	display: flex;
	justify-content: space-between;
	padding: 0.75rem 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

.stat-box-table-row:last-child {
	border-bottom: none;
}

.stat-box-table-label {
	font-size: 0.9375rem;
	color: #111827;
	font-weight: 400;
}

.stat-box-table-value {
	font-size: 0.9375rem;
	color: #111827;
	font-weight: 500;
	text-align: right;
}

.stat-box-table-column-headers {
	display: flex;
	justify-content: space-between;
	padding: 0 0 0.5rem 0;
	border-bottom: 2px solid rgba(0, 0, 0, 0.08);
	margin-bottom: 0.5rem;
}

.stat-box-table-column-header {
	font-size: 0.8125rem;
	font-weight: 600;
	color: #6B7280;
	text-transform: uppercase;
	letter-spacing: 0.05em;
}

/* Original stat box styles */
.stat-page {
	display: flex;
	flex-direction: column;
	background-color: #f5f5f7;
	border-radius: 16px;
	padding: 1.5rem;
	width: 100%;
	box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

.stat-box-title {
	font-size: 0.875rem;
	font-weight: 500;
	color: #6B7280;
	margin: 0;
	letter-spacing: 0.02em;
}

.stat-box-number {
	font-size: 1.75rem;
	font-weight: 600;
	color: #111827;
	margin: 0.75rem 0 0 0;
}

.stat-box-div {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.stat-box-div svg {
	color: #2446f0;
	font-size: 1.25rem;
}

.stat-box-table-loading, 
.stat-box-table-empty {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 2rem 0;
	color: #6B7280;
	font-size: 0.9375rem;
	font-style: italic;
}
