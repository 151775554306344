.grid-template-card {
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  width: 200px;
  height: 200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  /* Fixed height to match previous design */
}

.grid-template-card-front {
  /* border-radius: 12px; */
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
  overflow: hidden;
  position: relative;
  width: auto;
  /* max-width: 200px; */
  height: 200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  /* Fixed height to match previous design */
}

.grid-template-image-container {
  width: 100%;
  height: 128px;
  background-color: #f8f9fa;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.grid-template-no-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem;
}

.grid-template-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* object-position: 5% 14%; */
  /* object-fit: cover; */
}

.grid-template-upload-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.grid-template-image-container:hover .grid-template-upload-overlay {
  opacity: 1;
}

.grid-template-content-section {
  padding: 0px;
  flex-grow: 1;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 2%;
  width: 100%;
  flex-direction: column;
}

.grid-template-input-group {
  margin-bottom: 0.5rem;
}

.grid-template-editable-field {
  display: flex;
  align-items: center;
  gap: 8px;
}

.grid-template-field-text {
  margin: 0;
  font-size: 16px;
  color: #333;
  overflow-wrap: break-word;
  /* Ensures text breaks inside words if needed */
  word-break: break-word;
  /* Ensures word breaking works in all browsers */
  white-space: normal;
  /* Ensures text wraps normally */
}

.grid-template-field-text.title {
  font-weight: 500;
  font-size: 16px;
  overflow-wrap: break-word;
  /* Ensures text breaks inside words if needed */
  word-break: break-word;
  white-space: normal;


  text-align: center;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: normal;
}

.grid-template-field-text.title-placeholder {
  color: #aaa;
  /* Light grey color for placeholder */
  font-style: italic;
  /* Optional: Makes the placeholder text look distinct */
  font-weight: 500;
  font-size: 16px;
}

.grid-template-edit-input {
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 4px 8px;
  text-align: center;
  font-size: 12px;
  width: 100%;
}

.grid-template-edit-input:focus {
  border-color: #000;
  box-shadow: none;
}

.grid-template-edit-input.title {
  font-size: 12px;
  font-weight: 500;
}


.grid-template-actions {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 10;
  /* overflow: visible !important; */
}

.grid-template-edit-button,
.grid-template-save-button,
.grid-template-delete-button,
.grid-template-drag-icon {
  background: white;
  border: none;
  width: 26px !important;
  height: 26px !important;
  min-width: 26px !important;
  min-height: 26px !important;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  aspect-ratio: 1 / 1;
  /* Prevent Safari stretching */
  padding: 0 !important;
  /* Remove unwanted padding */
  margin: 0 !important;
  overflow: hidden;
  /* Ensures clean shape */
}

.grid-template-edit-button svg,
.grid-template-save-button svg,
.grid-template-delete-button svg {
  width: 16px;
  height: 18px;
  flex-shrink: 0;
  display: block !important;
  color: black !important;
}

/* button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  border: none;
  outline: none;
  background: none;
} */

.grid-template-edit-button,
.grid-template-save-button,
.grid-template-delete-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid-template-edit-button:hover,
.grid-template-save-button:hover,
.grid-template-delete-button:hover,
.grid-template-drag-icon:hover {
  background: #f8f9fa;
}

.grid-template-drag-icon {
  color: #6c757d;
  width: 22px;
  height: 22px;
  /* margin-left: 8px; */
  cursor: move;
}



.grid-template-delete-confirm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.grid-template-delete-confirm-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 90%;
}

.grid-template-delete-confirm-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.grid-template-delete-confirm-cancel,
.grid-template-delete-confirm-delete {
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.grid-template-delete-confirm-cancel {
  font-size: 0.7rem;
  border: 1px solid #d9d9d9;
  background: #ffffff;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.88);
}

.grid-template-delete-confirm-delete {
  background-color: rgb(241, 98, 98);
  color: white;
  font-size: 0.7rem;
}

.grid-template-delete-confirm-delete:hover {
  border: 1px solid rgb(241, 98, 98);
}




.grid-template-logo-container {
  position: relative;
  width: 100%;
  height: 128px;
  /* Adjust as needed */
  overflow: hidden;
}

.grid-template-logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.grid-template-logo-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid-template-logo-overlay-actions {
  display: flex;
  gap: 16px;
}

.grid-template-overlay-icon {
  color: grey;
  cursor: pointer;
  transition: transform 0.2s;


  background: white;
  border: none;
  padding: 2px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.grid-template-overlay-icon:hover {
  transform: scale(1.1);
}



.grid-template-image-placeholder {
  width: 100%;
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #d1d5db;
  border-radius: 8px;
}

.grid-template-upload-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.grid-template-no-image-container .grid-template-field-text.title {
  margin: 0;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  color: #333;
  word-break: break-word;
}

.grid-template-card-front:hover {
  background-color: #d0d2d5;
}


.dragging-template {
  opacity: 0.5;
}

.drag-over-template {
  border: 2px dashed #ccc;
}




.creator-card-grid-wrp-subcomponents {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1.5rem;
  padding: 1rem;
  width: 100%;
  max-width: 100%;
}

/* Add smooth transitions for drag and drop */
.creator-card-grid-wrp-subcomponents>div {
  transition: transform 0.2s ease, opacity 0.2s ease;
}

/* Add hover effect */
.creator-card-grid-wrp-subcomponents>div:hover {
  transform: translateY(-2px);
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .creator-card-grid-wrp-subcomponents {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 1rem;
  }
}

.draggable-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  width: 100%;
  touch-action: none;
  padding: 1rem;
  position: relative;
}

.draggable-grid-item {
  user-select: none;
  touch-action: none;
  position: relative;
  transition: transform 0.2s ease;
  will-change: transform;
}

.draggable-grid-item.dragging {
  opacity: 0.5;
  position: fixed;
  z-index: 1000;
}

@media (max-width: 768px) {
  .draggable-grid-container {
    grid-template-columns: 1fr;
    gap: 0.75rem;
    padding: 0.75rem;
  }
  
  .draggable-grid-item {
    margin-bottom: 0.5rem;
  }
}

.edit-mode-card {
  touch-action: auto !important;
  pointer-events: auto !important;
  user-select: text !important;
  -webkit-user-select: text !important;
  cursor: auto !important;
}

.edit-mode-card * {
  touch-action: auto !important;
  pointer-events: auto !important;
}

.draggable-grid-item.editing {
  touch-action: auto !important;
  pointer-events: auto !important;
  user-select: text !important;
  -webkit-user-select: text !important;
}