.drawer {
	width: 100%;
	height: auto;
}

.drawer-menu {
	width: 100%;
	height: auto;
	padding: 0;
}

/* Section styling */
.route-sections {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.route-section {
	margin-bottom: 8px;
	width: 100%;
}

.section-divider {
	height: 1px;
	background-color: rgba(0, 0, 0, 0.08);
	margin: 12px 16px;
}

.route-list {
	width: 100%;
	padding: 0 !important;
	background-color: transparent;
	border-radius: 8px;
	overflow: visible;
}

.route-list-item {
	margin-bottom: 4px;
	width: 100%;
}

.MuiListItemButton-root {
	border-radius: 8px !important;
	padding: 8px 12px !important;
	transition: all 0.2s ease;
	color: #666;
}

.MuiListItemButton-root:hover {
	/* background-color: #f5f5f5 !important; */
	background-color: #e5e5e5 !important;
	color: #333;
}

.route-list-icon {
	min-width: 40px !important;
	display: flex;
	justify-content: center;
}

.route-icon {
	font-size: 20px;
	color: inherit;
}

.route-list-text {
	margin: 0;
}

.route-list-text span {
	font-size: 14px;
	font-weight: 500;
}

.selected-menu {
	background-color: #f0f0f0 !important;
	color: #333 !important;
	position: relative;
}

.selected-menu::before {
	content: '';
	position: absolute;
	left: 0;
	top: 25%;
	height: 50%;
	width: 3px;
	background-color: black;
	border-radius: 0 3px 3px 0;
}

.selected-menu .route-icon,
.selected-menu .MuiTypography-root {
	color: #333 !important;
	font-weight: 500;
}

.locked-menu {
	opacity: 0.7;
	cursor: not-allowed;
}

.lock-icon {
	font-size: 12px;
	margin-left: 8px;
	color: #999;
}

.account-modal {
	text-align: center;
}

/* Mobile styles */
@media screen and (max-width: 767px) {
	.route-sections {
		padding: 8px 0;
	}
	
	.route-section {
		margin-bottom: 0;
	}
	
	.section-divider {
		margin: 8px 16px;
	}
	
	.route-list {
		display: flex;
		flex-direction: column;
		padding: 0 !important;
		-webkit-overflow-scrolling: touch;
	}
	
	.route-list::-webkit-scrollbar {
		display: none;
	}
	
	.route-list-item {
		margin-bottom: 4px;
		margin-right: 0;
		width: 100%;
	}
	
	.MuiListItemButton-root {
		flex-direction: row;
		padding: 12px !important;
		min-width: auto;
		width: 100%;
	}
	
	.selected-menu::before {
		left: 0;
		top: 25%;
		height: 50%;
		width: 3px;
		border-radius: 0 3px 3px 0;
	}
	
	.route-list-icon {
		min-width: 40px !important;
		margin-right: 12px !important;
	}
	
	.route-icon {
		font-size: 20px;
	}
	
	.route-list-text {
		margin-top: 0;
	}
	
	.lock-icon {
		position: static;
		margin-left: 8px;
		font-size: 12px;
	}
}

/* Ensure selected menu items have proper styling */
.MuiListItemButton-root.selected-menu,
.MuiListItemButton-root.selected-menu:hover {
	/* background-color: #f0f0f0 !important; */
	background-color: #f5f2f2 !important;
	color: #333 !important;
}

.MuiListItemButton-root.selected-menu .MuiListItemIcon-root,
.MuiListItemButton-root.selected-menu .MuiTypography-root {
	color: #333 !important;
}

.MuiListItemButton-root:not(.selected-menu) .MuiListItemIcon-root {
	color: #666;
}

.MuiListItemButton-root:not(.selected-menu) .MuiTypography-root {
	color: #666;
	font-weight: normal;
}

/* Extra component styling */
.extra-component {
	display: flex;
	gap: 20px;
	align-items: center;
	justify-content: center;
	color: #212529;
}

.fa-lock {
	color: #adb5bd;
	font-size: 0.8rem;
}
.route-user {
	display: flex;
	align-items: center;
	padding: 10px;
  }
  
  .route-user-avatar {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	object-fit: cover;
	margin-right: 10px;
  }
  
  .route-username {
	font-size: 14px;
	font-weight: 500;
  }
