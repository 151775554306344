.outer-container-links {
  width: auto;
  /* Set the width to 60% */
  margin: 0 auto;
  display: flex;
  padding: 1%;
  justify-content: justify;
  overflow-x: auto;

  /* overflow:hidden */
  /* border:1px solid red Allow horizontal scrolling */
}

.links-grid-container-links {
  display: flex;
  /* Arrange children in a row */
  flex-wrap: nowrap;
  /* Prevent wrapping to next line */
}

.creator-feed-image-wrapper-links p {
  white-space: normal;
  word-break: break-word;
}

.creator-feed-image-wrapper-links {
  /* border:1px solid red; */
  flex: 0 0 auto;
  width: 200px;
  /* height: 350px; */
  margin-right: 20px;
  padding-right: 0px;
  cursor: pointer;
  margin-bottom: 2%;
  border-radius: 15px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.creator-feed-card-links-image {
  width: 200px;
  height: 350px;
  /* margin-bottom: 2%; */
  object-fit: cover;
  border-radius: 1rem;
  box-shadow: 0 2px 0.75rem -0.25rem rgba(10, 20, 26, 0.08);

  padding-bottom: 0.325rem;
  transition: all 0.15s ease-in-out;
  position: relative;
}




.creator-feed-card-links-image video {
  height: 350px;
  object-fit: cover;
  border-radius: 1rem;
  margin-bottom: 2%;
  padding-bottom: 0rem;
}


.template-shop-btn-links {
  /* border:1px solid purple; */
  width: 200px;
  background-color: #e6e7eb;
  color: #000;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  -webkit-touch-callout: default !important;
}

.template-shop-btn-links:hover {
  /* background-color:#c9ccd1; */
  box-shadow: 0 0.031rem 0.125rem 0 rgba(10, 20, 26, 0.08);
  /* border:1px solid purple; */
  /* width: 200px; */
  background-color: #d0d2d5 !important;
  /* background: #d0d2d5 !important; */
}

.template-shop-btn-links-carousel {
  /* border:1px solid purple; */
  /* width: 200px; */
  width: 100%;
  background-color: #e6e7eb;
  color: #000;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  -webkit-touch-callout: default !important;
}

.template-shop-btn-links-carousel:hover {
  /* background-color:#c9ccd1; */
  box-shadow: 0 0.031rem 0.125rem 0 rgba(10, 20, 26, 0.08);
  /* border:1px solid purple; */
  /* width: 200px; */
  background-color: #d0d2d5 !important;
  /* background: #d0d2d5 !important; */
}


.horizontal-scroll-container {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;
  padding: 16px;
}

.horizontal-scroll-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.template-shop-btn-links-individual {
  /* border:1px solid purple; */
  width: 100%;
  background-color: #e6e7eb;
  color: #000;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  -webkit-touch-callout: default !important;
}

.template-shop-btn-links-individual:hover {
  /* background-color:#c9ccd1; */
  box-shadow: 0 0.031rem 0.125rem 0 rgba(10, 20, 26, 0.08);
  /* border:1px solid purple; */
  /* width: 200px; */
  background-color: #d0d2d5 !important;
  /* background: #d0d2d5 !important; */
}

.square-wrapper {
  aspect-ratio: 1/1;
  position: relative;
  /* width: 100%; */
  /* overflow: hidden; */
}

.square-wrapper .square-image,
.square-wrapper .square-video,
.square-wrapper video {
  /* width: 100%; */
  height: 200px;
  object-fit: cover;
}

.square-wrapper .template-shop-btn-links {
  position: absolute;
  bottom: 8px;
  right: 8px;
  z-index: 2;
}

.square-wrapper.not-available {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f5f5f5;
  padding: 1rem;
}

.feed-tab-media-wrapper .square-wrapper .square-image,
.feed-tab-media-wrapper .square-wrapper .square-video,
.feed-tab-media-wrapper .square-wrapper video {
  width: 100%;
  min-width: 140px;
  height: 140px;
  min-height: auto;
  object-fit: cover;
}

.feed-tab-media-wrapper .square-wrapper .square-image {
  margin-bottom: 5px;
}

.feed-tab-media-wrapper img {
  margin-bottom: 5px;
}

.feed-tab-media-wrapper .square-wrapper .square-video svg {
  bottom: 2%;
}
.individual-outer-container .creator-feed-image-wrapper-links {
  width: 100% !important;
} 

@media (max-width: 550px) {
  .square-wrapper{
    aspect-ratio: auto;
  }
}