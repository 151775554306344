/* Dashboard Component Styles */
.dashboard {
  width: 100%;
  height: 100vh;
  /* overflow: hidden; */
}

.dashboard-layout {
  /* display: flex; */
  height: 100%; 
  position: relative;
}

.content-container {
  flex: 1;
  /* padding: 24px; */
  padding: 80px;
  margin-left: 240px; /* Match the sidebar width */
  overflow-y: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  /* gap: 24px; */
  width: 100%;
}

.right-panel {
  flex: 1;
  min-width: 0;
  background-color: #ffffff;
  /* border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); */
  /* padding: 24px; */
  height: fit-content;
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.dashboard-mobile-show {
  display: none;
}

.extra-component {
  display: flex;
  align-items: center;
  gap: 12px;
}

.css-tlelie-MuiListItemText-root{
  text-align: left;
}

@media screen and (max-width: 991px) {
  .content-container {
    padding: 80px 30px;
  }
  .content-video-height video {
    min-height: 200px !important;
  }
}
/* Responsive styles */
@media screen and (max-width: 767px) {
  .dashboard {
    height: auto;
    overflow: visible;
  }
  
  .dashboard-layout {
    flex-direction: column;
    height: auto;
  }
  
  .content-container {
    padding: 16px;
    margin-left: 0;
    margin-bottom: 70px; /* Space for bottom navigation on mobile */
    overflow-y: visible;
  }

  /* .row {
    flex-direction: column;
    gap: 16px;
  } */

  .right-panel {
    width: 100%;
    padding: 16px;
    border-radius: none;
    box-shadow: none;
  }

  .dashboard-mobile-show {
    display: block;
    font-size: 24px;
    margin-bottom: 16px;
    display: none;
  }
} 