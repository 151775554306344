.skeleton-container {
    width: 100%;
    padding: 6px;
  }
  
  .skeleton-container.grid-layout {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
    padding-top: 2%;
  }
  
  @media screen and (max-width: 1099px) {
    .skeleton-container.grid-layout {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media screen and (max-width: 768px) {
    .skeleton-container.grid-layout {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  
  .skeleton-container.line-layout {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .grid-skeleton-card {
    background: white;
    border-radius: 8px;
    padding: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    height:200px;
  }
  
  .grid-skeleton-image {
    height: 128px;
    position: relative;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
  }
  
  .grid-skeleton-content {
    text-align: center;
    padding: 4px 0;
    position: relative;
    width: 100%;
  }
  
  .line-skeleton-card {
    background: white;
    border-radius: 8px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .line-skeleton-content {
    text-align: center;
    flex: 1;
  }

  @media screen and (max-width: 1099px) {
    .skeleton-container.grid-layout{
       grid-template-columns:repeat(3,1fr);
    }
  }
  @media screen and (max-width: 600px) {
    .skeleton-container.grid-layout {
      grid-gap: 15px;
      grid-template-columns: repeat(2, 1fr);
    }
  }