.account-value-input {
  width: 270px;
  border: 1px solid #ccc;
}

.account-edit-btn-name,
.account-edit-btn-phn,
.account-edit-btn-email,
.account-done-btn-name,
.account-done-btn-phn,
.account-done-btn-email {
  border: none;
  margin-left: 10px;
  border-radius: 40px;
  padding: 1px 4px 3px 4px;
}

.account-done-btn,
.account-edit-btn {
  border: none;
  margin-left: 1%;
  border-radius: 40px;
  height: fit-content;
  width: fit-content;
}

.account-values-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 1.5rem;
  margin: 2rem 0;
}

.account-tile {
  background: #f5f5f7;
  border-radius: 16px;
  padding: 1.75rem;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.account-tile::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #2446f0, #3b64f6);
  opacity: 0;
}

.account-tile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.account-tile-title {
  font-size: 0.875rem;
  font-weight: 500;
  color: #6B7280;
  letter-spacing: 0.02em;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.account-tile-title svg {
  color: #2446f0;
}

.account-tile-value {
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
  margin: 0.25rem 0 1.25rem;
  word-break: break-word;
}

.account-tile-action {
  margin-top: auto;
}

/* Target Ant Design buttons with .accountButton class to override default styles */
button.accountButton,
a.accountButton,
.ant-btn.accountButton {
  background-color: black !important;
  color: #fff !important;
  border: none !important;
  border-radius: 8px !important;
  padding: 0.75rem 1.25rem !important;
  font-weight: 500 !important;
  transition: all 0.2s ease !important;
  height: 40px !important;
  font-size: 0.9375rem !important;
  margin-top: 10px;
  margin-right: 10px;
}

button.accountButton:hover,
a.accountButton:hover,
.ant-btn.accountButton:hover {
  background-color: #333333 !important;
  transform: translateY(-1px) !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
  color: #fff !important;
  border-color: transparent !important;
}

button.accountButton:active,
a.accountButton:active,
.ant-btn.accountButton:active {
  transform: translateY(0) !important;
}

/* Keeping the original .accountButton for backward compatibility */
.accountButton {
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 0.75rem 1.25rem;
  font-weight: 500;
  transition: all 0.2s ease;
  height: auto;
  font-size: 0.9375rem;
}

.accountButton:hover {
  background-color: #333333;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.accountButton:active {
  transform: translateY(0);
}

/* Animation for new elements */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.account-tile {
  animation: fadeIn 0.4s ease-out;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .account-values-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  
  .account-tile {
    padding: 1.5rem;
  }
}

/* Custom styling for specific account tiles */
.account-tile.subscription {
  background: #f5f5f7;
}

.account-tile.profile-picture {
  align-items: center;
  text-align: center;
}

.account-tile.profile-picture img {
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: 0 0 0 2px rgba(36, 70, 240, 0.3);
}

.account-grid-item {
  border-radius: 10px;
  background: #f1f1f1;
  padding: 20px;
}

.account-grid-item-key-span {
  display: flex;
}

.account-grid-item-key {
  font-size: 1.25rem;
}

/* .account-values-profiles{
  padding-top: 1.25rem;
  padding-bottom: 0rem;
  padding-right: 2.7rem;
} */
.account-grid-item-key-status-btn {
  background-color: #e0dfdf;
  color: grey;
  border: 0.5px solid grey;
  border-radius: 10px;
  font-size: 0.8rem;
  width: fit-content;
  height: fit-content;
  margin-left: auto;
}

.account-grid-item-key-status-btn.active {
  color: green;
  border: 0.5px solid green;
}

.account-grid-item-value {
  font-size: 1rem;
}

.otp-input {
  height: 2.5rem;
  margin-top: 2%;
  width: 100%;
  text-align: center;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.alignModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
}

.modal-verify-btn {
  background: black;
  color: white;
  margin-top: 10px;
  display: block !important;
}

.errorText {
  color: red;
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
}

.account-links {
  margin-top: -2%;
  width: 100%;
  font-size: 1.5rem;
}

.account-social-logins-heading {
  /* margin-top: -2%; */
  width: 100%;
  font-size: 16px;
  margin-bottom: .5rem !important;
  font-weight: 500;
  /* display: flex; */
}

.account-add-btn {
  border: none;
  width: fit-content;
  height: fit-content;
  background-color: #fff;
  margin-right: 1%;
}

.account-add-btn-icon {
  height: 25px;
}

.verified-text {
  margin-top: 2%;
  color: rgb(32, 131, 32);
}

.account-modal {
  top: 40%;
}

.account-modal .ant-btn-default {
  display: none;
}

.ant-btn .ant-tabs {
  font-family: circular, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.verify-btn {
  /* color: rgb(243, 84, 84);
  border: none;
  background-color: #f1f1f1;
  margin-top: 2%;
  font-size: 1rem; */

  color: white;
  margin-top: 2%;
  /* font-size: 1rem; */
  /* border: none; */
  border-radius: 5px;
  /* padding: 7px; */
  background-color: #0F1013;

  font-size: 14px;
  padding: 9px;
}

.modal-error-text {
  color: rgb(207, 80, 80);
  margin-top: 1.5%;
  font-size: 0.9rem;
}

.manage-subscription {
  color: black;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: rgb(239, 240, 236);
  border-radius: 5px;
  border: none;
}


@media screen and (max-width: 600px) {

  /* .account-values-profiles{
  padding-top: 1.25rem;
  padding-bottom: 0rem;
  padding-right: 2.7rem;
} */

  .account-values-grid {
    grid-template-columns: 100%;
  }
}
@media screen and (max-width: 320px) {
  .account-value-input {
    border: none;
    width: 250px;
    border: 1px solid #ccc;
  }

}

/* @media screen and (max-width: 400px){

  .account-values-grid {
    grid-template-columns: repeat(auto-fill, 350px);
  }

} */
.changeButton {
  background: black;
  color: white;
  margin-top: 10px;
  display: block;
}
.saveButtonDiv{
  display: flex; 
  justify-content: space-between; 
  width: 60%;
}
.saveButton {
  background: black;
  color: white;
  margin-top: 10px;
  width: 45%;
}

.accountButton {
  background: black;
  color: white;
  /* margin-top: 10px; */
  /* width: auto;
  height: auto; */
}
.errorText{
  color: red;
  text-align: center;
  margin-top: 2%;
}
.nameError{
  color: red;
  font-size: 0.9rem;
}
.mt-8 {
  margin-top: 8;
}
.background-none {
  background: none;
  border: 0;
}
.mb-30 {
  margin-bottom: 30px;
}

.profile-upload{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
}

.profile-upload .ant-image {
  height: 100%;
}

.profile-upload img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-top: 5%;
}

/* Button Group Styling for Save/Cancel pairs */
.account-button-group {
  display: flex;
  gap: 0.75rem;
  margin-top: 0.5rem;
}

.account-button-group button {
  flex: 1;
  min-width: 80px;
}

/* Cancel button styling */
button.cancelButton,
a.cancelButton,
.ant-btn.cancelButton {
  background-color: transparent !important;
  color: #333 !important;
  border: 1px solid #e5e7eb !important;
  border-radius: 8px !important;
  padding: 0.75rem 1.25rem !important;
  font-weight: 500 !important;
  transition: all 0.2s ease !important;
  height: 40px !important;
  font-size: 0.9375rem !important;
  margin-top: 10px;
  margin-right: 10px;
  line-height: 24px !important;
}

button.cancelButton:hover,
a.cancelButton:hover,
.ant-btn.cancelButton:hover {
  background-color: #f5f5f5 !important;
  transform: translateY(-1px) !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05) !important;
  border-color: #d1d5db !important;
}

button.cancelButton:active,
a.cancelButton:active,
.ant-btn.cancelButton:active {
  transform: translateY(0) !important;
}

.react-tooltip {
  z-index: 10000 !important;
}