/* Feed tab styling to match other dashboard sections */

/* Base tab styles to match Analytics and Controls */
.ant-tabs {
    width: 100%;
    margin-bottom: 0;
    padding-left: 0;
}

.ant-tabs > .ant-tabs-nav {
    margin: 0 !important;
    padding: 0 !important;
}

/* Remove ink bar (the default blue line) and replace with custom styling */
.ant-tabs-ink-bar {
    height: 3px !important;
    background-color: black !important;
}

/* Tab styling */
.ant-tabs .ant-tabs-tab {
    padding: 12px 0 !important;
    margin: 0 24px 0 0 !important;
    font-weight: 400;
    font-size: 16px;
    background: transparent !important;
    border: none !important;
    border-bottom: 3px solid transparent !important;
}

.ant-tabs-tab.ant-tabs-tab-active {
    border: none !important;
    border-bottom: 3px solid black !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: black !important;
    font-weight: 500;
}

/* Remove border from nav */
.ant-tabs-nav::before {
    border-bottom: 0 !important;
}

/* Fix alignment of tabs */
.ant-tabs .ant-tabs-nav .ant-tabs-nav-list {
    justify-content: flex-start !important;
    width: 100%;
    padding: 0;
    margin: 0;
}

/* Styling for the tab-pane with lock icon */
.tab-pane {
    display: flex;
    align-items: center;
    gap: 6px;
}

.fa-lock {
    font-size: 12px;
    margin-right: 2px;
}

/* Tab content styling */
.ant-tabs .ant-tabs-content {
    padding-left: 0 !important;
    margin-left: 0 !important;
    padding-top: 16px;
}

.ant-tabs-content-holder {
    padding-left: 0 !important;
    margin-left: 0 !important;
}

.ant-tabs-tabpane {
    padding-left: 0 !important;
    margin-left: 0 !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .ant-tabs .ant-tabs-tab {
        padding: 10px 0 !important;
        margin: 0 20px 0 0 !important;
        font-size: 15px;
    }
}

/* Modal styling for the upgrade modal */
.modal-text {
    font-size: 1rem;
    line-height: 1.5;
    color: #333;
    margin-bottom: 1.5rem;
}

.text-right {
    text-align: right;
}

.upgrade-subscription {
    background-color: black;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 0.75rem 1.25rem;
    font-weight: 500;
    font-size: 0.9375rem;
    cursor: pointer;
    transition: all 0.2s ease;
}

.upgrade-subscription:hover {
    background-color: #333;
} 