/* ContentCharts.css */
.filter-section {
  margin: 10px 0;
}

.filter-section p {
  margin-bottom: 0;
}

.segmented-container {
  overflow-x: auto;
  /* Enable horizontal scrolling */
  white-space: nowrap;
  /* Prevent line breaks */

  padding-bottom: 10px;
  padding-top: 0px;
  /* background-color: #f0f2f5; Adjust background color */
  border-radius: 5px;
  /* Adjust border radius */
  background-color: none;
  display: flex;
  gap: 10px;
  padding-left: 0%;
  margin-top: 2px;
}

/* .segmented-container .ant-segmented-item {
  display: inline-block; 
  margin-right: 10px; 
} */
.segmented-container .ant-btn-default {
  background: white;
  border: solid black 1px;
  border-radius: 9px;
  padding: 4px 12px;
  cursor: pointer;
  white-space: nowrap;
  font-size: .9rem;
}

.ant-modal-footer .ant-btn-default {
  box-shadow: 0 3px 0 rgb(223 218 218);
}

.sup-analytics-grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Breakdown title styling */
.breakdown-title {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  margin: 28px 0 16px 0;
  padding: 0;
  position: relative;
}

.breakdown-title::after {
  content: '';
  display: block;
  width: 30px;
  height: 3px;
  background-color: #000;
  margin-top: 8px;
}

.shoppable-btn:hover {
  color: #fff !important;
  border-color: #0d0d0d !important;
  background: #000 !important;
}

.analytics-chartClicks-thumbnail {
  /* width: 15%; */
  max-width: 90%;
  border-radius: 5px;
}

.ant-segmented-item {
  border: 1px dashed;
  margin: 2px;
  background-color: none;
}

.ant-segmented-group {
  background-color: none;
}

.ant-segmented {
  background-color: none;
}

.tabAnchor a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.recharts-layer .recharts-cartesian-axis-tick {
  text-wrap: wrap;
  width: 10% !important;
}

.recharts-surface{
  cursor: pointer;
}

.spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.analyticsTable {
  border-bottom: none;
  margin-bottom: 12px;
  padding: 14px 16px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 16px;
  background-color: #f5f5f7;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
  transition: all 0.2s ease;
}

.analyticsTable:hover {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.06);
  transform: translateY(-1px);
  background-color: #f0f0f2;
}

.analyticsTable:last-child {
  margin-bottom: 16px;
}

/* Name in the middle column */
.analyticsTable .item-name {
  font-weight: 600;
  color: #212121;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Clicks count styling */
.analyticsTable .right-aligned {
  text-align: right;
  font-size: 14px;
  color: #3b3b3b;
  font-weight: 500;
}

.analyticsTable .right-aligned div {
  margin-bottom: 4px;
}

/* Metric label styling */
.metric-label {
  font-weight: 400;
  color: #717171;
  margin-right: 4px;
}

/* Thumbnail styling */
.analytics-chartClicks-thumbnail-div {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
}

.analytics-chartClicks-thumbnail-div img {
  /* width: 100%;
  height: 100%; */
  object-fit: cover;
}

/* Container for the analytics table */
.analytics-table {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  width: 100%;
  margin-top: 16px;
  padding: 0;
}

/* Responsive adjustment for analytics table */
@media screen and (max-width: 768px) {
  .analytics-table {
    grid-template-columns: 1fr;
  }
}

.custom-tooltip {
  z-index: -1;
  background: white;
  border: 1px solid #d5d5d5;
  border-radius: 5;
  line-height: 20px;
  width: auto;
}

.loaderMyLink {
  margin-top: 2%;
  margin-bottom: 4%;
  width: 100%;
  height: 100%;
}

.agchartSpin {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.loadingPageAnalytics {
  margin-top: 1%;
  margin-bottom: 4%;
  cursor: pointer;
  /* border: 1px solid #f1f1f1; */
  width: 100%;
  cursor: pointer;
  /* margin-left: 3%; */
}

.fullDiv {
  width: 100%;
  height: 100%;
}

.clicks {
  display: flex;
  justify-content: center;
  margin-top: 2%;
  flex-wrap: wrap;
}

.clicks div {
  margin-right: 20px;
}

.mt-2 {
  margin-top: 4px;
}

.social {
  margin: 2%;
  padding: 3px;
}

.clicks-color {
  color: #42b0ba;
}

.p-3px {
  padding: 3px;
}

.mt-15px {
  margin-top: 15px;
}

.mt-5 {
  margin-top: 5%;
}

.user-clr {
  color: #8685EF;
}

  .search-clr {
    color: #404040;
  }

.page-views-clr {
  color: #FF69B4;
}

.clicks-clr {
  color: #00C6CF;
}

.p-3px {
  padding: 3px;
}

.m-2 {
  margin: 2%;
}

.mr-20px {
  margin-right: 20px;
}


.right-aligned {
  text-align: right;
  /* Align text to the right */
  margin: 0;
  /* Reset margin if needed */
  padding: 0;
  /* Reset padding if needed */
}



.analytics-chartClicks-thumbnail-div-placeholder-div {
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  max-width: 25%;
  height: 60px;
  border-radius: 5px;

}
.analytics-newsletter-thumbnail {
  background: lightgrey;
  color: #fff;
  word-wrap: break-word;
  white-space: break-spaces;
  overflow: hidden;
  text-align: center;
  font-size: 8px;
  min-width: 60px;
  min-height: 70px;
  display: flex;
  align-items: center;
}