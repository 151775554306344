.instagram-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* min-height: 100vh; */
  /* background-color: #0f1013; */
}

.instagram-card {
  width: 384px;
  background-color: #27282b;
  border-radius: 8px;
  padding: 24px;
}

.card-header {
  text-align: center;
  margin-bottom: 14px;
}

.instagram-icon-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.instagram-icon {
  width: 32px;
  height: 32px;
  color: white;
}

.card-title {
  font-size: 24px;
  font-weight: 600;
  color: white;
  margin-bottom: 8px;
}

.card-description {
  color: #d1d5db;
  font-size: 14px;
}

.input-wrapper {
  position: relative;
  margin-bottom: 16px;
}

.input-prefix {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #9ca3af;
}

.insta-username {
  padding: 8px 12px 8px 150px !important;
}

.instagram-input {
  width: 100%;
  padding: 8px 12px 8px 32px;
  background-color: #0f1013;
  color: white;
  border: 1px solid #4b5563;
  border-radius: 4px;
}

.instagram-input:focus {
  outline: none;
  border-color: #6b7280;
}

.error-message {
  color: #ef4444;
  margin-bottom: 16px;
  font-size: 14px;
}

.next-button {
  width: 100%;
  background-color: #2449fd;
  color: #ffffff;
  padding: 8px 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
}

.next-button:hover {
  /* background-color: #f3f4f6; */
}

.next-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.arrow-icon {
  margin-left: 8px;
  width: 16px;
  height: 16px;
}

.profile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  /* margin-bottom: 32px; */
}

.profile-image-container {
  position: relative;
  width: 100px;
  height: 100px;
}

.profile-image {
  position: relative;
  width: 100%;
  height: 100%;
  /* border-radius: 50%; */
  overflow: hidden;
}

.review-profile-card .edit-profile{
  padding: 2px 6px;
}

.profile-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.edit-image-button {
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  border: none;
  border-radius: 50%;
  padding: 6px;
  cursor: pointer;
  color: white;
}

.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
}

.profile-info {
  text-align: center;
  width: 100%;
}

.profile-name {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 24px;
  font-weight: 600;
  color: white;
  margin-bottom: 8px;
}

.edit-button {
  background: none;
  border: none;
  color: #9ca3af;
  cursor: pointer;
  padding: 4px;
}

.profile-description {
  color: #9ca3af;
  font-size: 14px;
  line-height: 1.5;
}

.connected-platforms {
  margin-top: 24px;
}

.section-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  color: white;
}

.platform-item {
  padding: 12px;
  background: #1a1a1a;
  border: 1px solid #333;
  border-radius: 8px;
  margin-bottom: 8px;
}

/* Specific styling for platform items in the review page */
.review-platform-item {
  padding: 14px 16px;
  margin-bottom: 0;
  background: rgba(39, 40, 43, 0.75);
  border: 1px solid rgba(60, 63, 71, 0.5);
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.review-platform-item:hover {
  background: rgba(45, 46, 50, 0.85);
}

.platform-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.platform-name {
  color: white;
  font-size: 16px;
  font-weight: 500;
  margin-right: 16px;
  flex-shrink: 0;
}

.platform-username {
  color: rgba(255, 255, 255, 0.65);
  font-size: 14px;
  max-width: 170px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.action-buttons {
  display: flex;
  gap: 12px;
  margin-top: 24px;
}

.back-button {
  flex: 1;
  padding: 8px 16px;
  background: transparent;
  border: 1px solid #333;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.back-button:hover {
  background: #262626;
}

.review-profile-card {
  width: 700px !important;
  background-color: rgba(27, 28, 32, 0.6);
  backdrop-filter: blur(8px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

@media (max-width: 450px) {
  .review-profile-card {
    width: 100%;
  }

  .review-profile-card .action-buttons {
    flex-direction: column-reverse;
  }
}

/* Completion Step Styles */
.completion-animation {
  display: flex;
  justify-content: center;
  margin: 32px 0;
}

.success-checkmark {
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

.check-icon {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
  /* border: 4px solid #22c55e; */
}

.check-icon::before {
  top: 3px;
  left: -2px;
  width: 30px;
  transform-origin: 100% 50%;
  border-radius: 100px 0 0 100px;
}

.check-icon::after {
  top: 0;
  left: 30px;
  width: 60px;
  transform-origin: 0 50%;
  border-radius: 0 100px 100px 0;
  animation: rotate-circle 4.25s ease-in;
}

.icon-line {
  height: 5px;
  background-color: #22c55e;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 10;
}

.icon-line.line-tip {
  top: 46px;
  left: 14px;
  width: 25px;
  transform: rotate(45deg);
  animation: icon-line-tip 0.75s;
}

.icon-line.line-long {
  top: 38px;
  right: 8px;
  width: 47px;
  transform: rotate(-45deg);
  animation: icon-line-long 0.75s;
}

.icon-circle {
  top: -4px;
  left: -4px;
  z-index: 10;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  box-sizing: content-box;
  border: 4px solid #22c55e;
}

.icon-fix {
  top: 8px;
  width: 5px;
  left: 26px;
  z-index: 1;
  height: 85px;
  position: absolute;
  transform: rotate(-45deg);
  background-color: transparent;
}

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }

  5% {
    transform: rotate(-45deg);
  }

  12% {
    transform: rotate(-405deg);
  }

  100% {
    transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }

  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }

  100% {
    width: 25px;
    left: 14px;
    top: 46px;
  }
}

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }

  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

/* Platform specific styles */
.onboarding-page .platform-card {
  background-color: #262626;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 12px;
}

.platform-header {
  /* width: 100%; */
  width: 45vh;
  padding: 8px;
  background-color: #333;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: white;
  fill: white;
  gap: 5px;
}

.platform-header.expanded {
  border-bottom: 1px solid #404040;
}

.platform-header-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.platform-check {
  color: #22c55e;
  margin-left: 8px;
}

.dropdown-icon {
  font-size: 24px;
  transition: transform 0.2s ease;
  color: #9ca3af;
  margin-left: auto;
}

.dropdown-icon.expanded {
  transform: rotate(180deg);
}

.platform-content {
  padding: 16px;
  background-color: #262626;
}

.platform-action-button {
  width: 100%;
  padding: 6px 16px;
  /* margin-top: 16px; */
  background-color: white;
  color: #1a1a1a;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.platform-action-button.disconnect {
  background-color: transparent;
  border: 1px solid #ef4444;
  color: #ef4444;
}

.platform-action-button:hover {
  opacity: 0.9;
}

.platform-icon {
  font-size: 20px;
  color: white;
  min-width: 20px;
  /* display: inline-block; */
  margin-right: 12px;
  flex-shrink: 0;
}

.platform-verified-badge {
  color: #3897f0; /* Instagram's verified badge blue color */
  font-size: 18px; /* Increased size to match OnboardingPage.css */
  margin-right: 8px;
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
}

.instagram-container fieldset.userdashboard-profile legend {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 4px 8px;
  background: rgba(0, 0, 0, 0.25);
  margin-left: auto;
  margin-right: auto;
  float: none;
  width: auto;
  margin-bottom: 0;
  border-radius: 50px;
}

.instagram-container .creator-dashboard-profile-description {
  color: #fff;
}

.instagram-container .platform-action-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.animate-spin {
  animation: spin 1s linear infinite;
  font-size: 1.4rem;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* .platform-header {
  width: 100%;
  padding: 16px;
  background-color: #333;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: white;
}

.platform-header.expanded {
  border-bottom: 1px solid #404040;
}

.platform-header-content {
  display: flex;
  align-items: center;
  gap: 8px;
} */

.platform-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 25px;
}

.category-title {
  color: #ffffff;
  font-size: 1.2rem;
  margin-bottom: 16px;
  padding-left: 8px;
  border-left: 2px solid #3B82F6;
}

.category-title-review {
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 10px;
  padding-left: 8px;
  border-left: 2px solid #3B82F6;
  font-weight: 600;
}

.platform-category-review {
  padding: 0.1rem;
  margin-bottom: 24px;
}

.social-platforms {
  grid-template-columns: 1fr 1fr;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.platforms-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  height: 100%;
}

.platform-category {
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 1.5rem;
  background-color: #27282b;
  border-radius: 8px;
}

.platforms-container {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.flex-column {
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 650px) {
  .platforms-grid {
    grid-template-columns: 1fr;
    padding: 1.5rem;
  }

  .social-platforms,
  .other-platforms {
    grid-column: auto;
  }

  .platform-container {
    margin: 15px;
  }

  .platform-header {
    width: 40vh;
  }
  .platforms-grid-review {
    grid-template-columns: 1fr;
  }
  .platform-items-container {
    grid-template-columns: 1fr;
  }
  .review-profile-card {
    max-width: 65% !important;
    padding: 16px !important;
  }
}

@media (max-width: 950px) {
  .platform-header {
    width: 35vh;
  }
}

@media (max-width: 770px) {
  .platform-header {
    width: 30vh;
  }
}

/* Desktop view for connected platforms */
.platforms-desktop-view {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.category-header {
  margin-bottom: 12px;
}

.platform-items-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

/* Specific styling for platform items in the review page */
.review-platform-item {
  padding: 10px;
  margin-bottom: 0;
  background: rgba(33, 34, 38, 0.5);
  border: 1px solid rgba(48, 49, 54, 0.5);
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.review-platform-item:hover {
  background: rgba(38, 39, 44, 0.6);
}

/* Responsive adjustments for smaller screens */
@media (max-width: 650px) {
  .platform-items-container {
    grid-template-columns: 1fr;
  }
}