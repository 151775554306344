/* Sidebar Component Styles */
.sidebar {
  display: flex;
  flex-direction: column;
  width: 240px;
  /* width: 15%; */
  min-width: 240px;
  height: 100%;
  background-color: #f5f2f2;
  border-right: 1px solid #f0f0f0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  padding: 16px 0;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
}

.sidebar-logo {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 16px 8%;
  margin-bottom: 24px;
}

.logo-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-image {
  width: 32px;
  height: 32px;
}

.logo-text {
  font-size: 24px;
  font-weight: 700;
  color: #6366f1;
  letter-spacing: -0.5px;
}

.sidebar-nav {
  flex: 1;
  padding: 0 8px;
}

.sidebar-footer {
  margin-top: auto;
  padding: 16px;
  border-top: 1px solid #f0f0f0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.sidebar-user {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.sidebar-user:hover {
  background-color: #f5f5f5;
}

.user-avatar {
  width: 32px !important;
  height: 32px !important;
  font-size: 14px !important;
  background-color: #e8e8e8 !important;
  color: #212529 !important;
}

.username {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu-icon {
  margin-right: 8px;
  font-size: 18px;
  color: #666;
}

/* Mobile menu toggle button */
.mobile-menu-toggle {
  display: none;
  cursor: pointer;
  padding: 5px 5px 5px 14px;
  gap: 10px;
  font-size: 22px;
  font-size: 22px;
    box-shadow: 0px 0px 8px -5px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    padding: 4px 4px 4px 10px;
    border-radius: 99px;
    background: #ffffff;
    align-items: center;
}
.header-dashboard-img {
  height: 38px;
  width: 38px;
  border-radius: 50%;
  object-fit: contain;
}
.mobile-buttons{
  display: none;
}

.hamburger-icon {
  font-size: 24px;
  color: #333;
}

.css-10hburv-MuiTypography-root {
  font-family: circular, Helvetica Neue, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

/* Responsive styles */
@media screen and (max-width: 767.98px) {
  .sidebar {
    width: 100%;
    height: 60px;
    flex-direction: row;
    padding: 0;
    top: 0;
    bottom: auto;
    border-right: none;
    border-bottom: 1px solid #f0f0f0;
    justify-content: space-between;
    align-items: center;
  }

  .sidebar-logo {
    display: flex;
    margin-bottom: 0;
    padding: 0 16px;
    justify-content: flex-start;
  }

  .logo-icon {
    width: 32px;
    height: 32px;
  }

  .homepage-icon {
    width: 32px;
    height: 32px;
  }

  .sidebar-nav {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #f5f2f2;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* padding: 16px; */
    z-index: 99;
    transform: translateY(-100%);
    transition: transform 0.3s ease;
    opacity: 0;
    visibility: hidden;
    height: auto;
    /* max-height: 90vh; */
    overflow-y: auto;
  }

  .sidebar-nav.mobile-menu-open {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
  }

  .mobile-menu-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    /* margin-right: 16px; */
    position: relative;
    border-radius: 99px;
    padding: 4px 4px 4px 10px;
    gap: 10px;
    font-size: 22px;
    box-shadow: 0px 0px 8px -5px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    color: #404040;
  }

  .mobile-buttons {
    display: flex;
  }

  .sidebar-footer {
    display: flex;
    flex-direction: row;
    border-top: none;
    padding: 0 16px;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .sidebar-user {
    display: none;
    padding: 8px 0;
  }

  /* .username {
    display: none;
  } */
} 
.mobile-menu-divider {
  background-color: rgba(0, 0, 0, 0.12);
  width: auto !important;
  margin: 0px;
}

/* .ant-dropdown .ant-dropdown-menu {
  background-color: #f5f2f2;
        box-shadow: 0 4px 6px #0000001a;
        height: auto;
        left: 0;
        opacity: 0;
        overflow-y: auto;
        padding: 16px;
        position: fixed;
        top: 60px;
        transform: translateY(-100%);
        transition: transform .3s ease;
        visibility: hidden;
        width: 100%;
        z-index: 99;
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
} */