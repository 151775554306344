.date-selector {
	display: flex;
	flex-wrap: nowrap;
	gap: 10px;
	overflow-x: auto;
	padding-bottom: 10px; /* Add padding for scrollbar */
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: thin; /* Firefox */
}

/* Customize scrollbar for better aesthetics */
.date-selector::-webkit-scrollbar {
	height: 4px;
}

.date-selector::-webkit-scrollbar-track {
	background: rgba(0, 0, 0, 0.05);
	border-radius: 10px;
}

.date-selector::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.15);
	border-radius: 10px;
}

/* Date selector button styling to match filter buttons */
.date-selector-btn {
	border-radius: 8px;
	padding: 6px 14px;
	height: auto;
	font-size: 14px;
	font-weight: 500;
	border: 1px solid rgba(0, 0, 0, 0.1);
	transition: all 0.2s ease;
	background-color: white;
	color: rgba(0, 0, 0, 0.7);
	white-space: nowrap;
	flex-shrink: 0;
	cursor: pointer;
	margin: 0;
}

.date-selector-btn:hover {
	transform: translateY(-1px);
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
	background-color: #fafafa;
}

/* Active date selector button styling */
.date-selector-btn.active {
	background: #000000 !important;
	border-color: transparent !important;
	color: white !important;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
