/* 
 * DetailDivStyles.css
 * Targeted styling for the detail-margin div in FeedNew and ProductBank components
 */

/* Main container styling */
.detail-margin {
  padding: 16px 0 0px;
  margin-bottom: 20px;
  background-color: #f5f5f7;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

/* Special styling for detail-margin in non-active tabs */
.detail-margin-simple {
  padding: 16px 0 0px;
  margin-bottom: 20px;
  background-color: #f5f5f7;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.detail-margin-simple .ant-row {
  padding: 0 16px;
  margin-bottom: 16px !important;
}

/* Search row styling */
.detail-margin .ant-row {
  padding: 0 16px;
  margin-bottom: 16px !important;
}

/* Adjust column widths for button and search */
.detail-margin .ant-row > .ant-col:first-child {
  flex: 0 0 auto !important;
  width: auto !important;
  margin-right: 16px;
}

.detail-margin .ant-row > .ant-col[flex="auto"],
.detail-margin-simple .ant-row > .ant-col[flex="auto"] {
  flex: 1 !important;
  max-width: 65% !important;
}

/* Add button styling - updated to include text and plus sign */
.detail-margin .product-bank-add-btn-icon {
  background: #000000;
  border: none;
  color: white;
  border-radius: 8px;
  min-width: 150px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease;
  padding: 0 16px;
  position: relative;
}

/* Ensure + icon is displayed properly */
.detail-margin .product-bank-add-btn-icon .anticon {
  font-size: 16px;
  margin-right: 4px;
}

/* Add text label after the icon */
.detail-margin .product-bank-add-btn-icon::after {
  content: "";
  margin-left: 8px;
  font-size: 14px;
  font-weight: 500;
}

/* Specific label for Product Bank */
#add-product-icon.product-bank-add-btn-icon::after {
  content: "Add Product";
}

/* Specific label for Feed */
#create-content-modal.product-bank-add-btn-icon::after {
  content: "Add Content";
}

.detail-margin .product-bank-add-btn-icon:hover {
  background: #000000;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.detail-margin .product-bank-add-btn-icon:active {
  transform: translateY(0px);
}

/* Filter section styling */
.detail-margin .filter-section {
  padding: 0 16px;
  margin-top: 20px;
}

.detail-margin .filter-section p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 10px;
}

/* Filter buttons container - make it scrollable */
.detail-margin .segmented-container {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  margin-bottom: 8px;
  overflow-x: auto;
  padding-bottom: 10px; /* Add padding for scrollbar */
  -ms-overflow-style: none; /* IE and Edge */
  /* scrollbar-width: thin; */
}

/* Customize scrollbar for better aesthetics */
.detail-margin .segmented-container::-webkit-scrollbar {
  height: 4px;
}

.detail-margin .segmented-container::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.detail-margin .segmented-container::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

/* Filter button styling */
.detail-margin .segmented-container .ant-btn {
  border-radius: 8px;
  padding: 6px 14px;
  height: auto;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  background-color: white;
  color: rgba(0, 0, 0, 0.7);
  white-space: nowrap;
  flex-shrink: 0;
  margin-top: 2px;
}

.detail-margin .segmented-container .ant-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  background-color: #fafafa;
  margin-top: 2px;
}

/* Active filter button styling */
.detail-margin .segmented-container .ant-btn[style*="background: rgb(59, 59, 59)"] {
  background: #000000 !important;
  border-color: transparent !important;
  color: white !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

/* Search input styling - override the component's default styling */
.detail-margin .search-input-wrapper,
.detail-margin-simple .search-input-wrapper {
  background-color: white !important;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  border-radius: 8px !important;
  height: 40px !important;
  transition: all 0.2s ease !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08) !important;
  width: 100% !important;
}

.detail-margin .search-input-wrapper:focus-within,
.detail-margin-simple .search-input-wrapper:focus-within {
  border-color: #000000 !important;
  background-color: white !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
}

.detail-margin .search-input-box,
.detail-margin-simple .search-input-box {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.85) !important;
  font-size: 14px !important;
}

.detail-margin .search-input-box::placeholder,
.detail-margin-simple .search-input-box::placeholder {
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: 500 !important;
}

.detail-margin .search-input-icon-btn,
.detail-margin-simple .search-input-icon-btn {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

/* Add highlight effect to search icon */
.detail-margin .search-input-icon-btn .anticon,
.detail-margin-simple .search-input-icon-btn .anticon {
  font-size: 16px !important;
}

/* Loading skeleton styling within the detail-margin */
.detail-margin .react-loading-skeleton,
.detail-margin-simple .react-loading-skeleton {
  background-color: rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
}

/* Add the filter section styling for detail-margin-simple */
.detail-margin-simple .filter-section {
  padding: 0 16px;
  margin-top: 20px;
}

/* Filter button styling in simple margin */
.detail-margin-simple .segmented-container {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  margin-bottom: 8px;
  overflow-x: auto;
  padding-bottom: 10px;
  -ms-overflow-style: none;
  scrollbar-width: thin;
}

.detail-margin-simple .segmented-container::-webkit-scrollbar {
  height: 4px;
}

.detail-margin-simple .segmented-container::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.detail-margin-simple .segmented-container::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.detail-margin-simple .segmented-container .ant-btn {
  border-radius: 8px;
  padding: 6px 14px;
  height: auto;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  background-color: white;
  color: rgba(0, 0, 0, 0.7);
  white-space: nowrap;
  flex-shrink: 0;
}

.detail-margin-simple .segmented-container .ant-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  background-color: #fafafa;
}

.detail-margin-simple .segmented-container .ant-btn[style*="background: rgb(59, 59, 59)"] {
  background: #000000 !important;
  border-color: transparent !important;
  color: white !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.detail-margin-simple .filter-section p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 10px;
}

.detail-margin-simple .filter-section .segmented-container .react-loading-skeleton {
  background-color: rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
}

/* Enhance Empty state message for broken links tab */
#root .empty-state-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 2.5rem;
  margin: 2rem auto;
  text-align: center;
  border-radius: 16px;
  background-color: #f9f9fb;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.02);
  width: 100%;
  max-width: 100%;
}

/* Remove hover animation */
#root .empty-state-message:hover {
  /* transform: translateY(-3px); - removed */
  /* box-shadow: 0 6px 18px rgba(0, 0, 0, 0.05); - removed */
}

#root .empty-state-message .empty-state-icon {
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
  display: block;
  line-height: 1;
}

#root .empty-state-message p {
  margin: 0;
  font-size: 1.125rem;
  font-weight: 500;
  color: #333;
  line-height: 1.5;
  letter-spacing: 0.01em;
  max-width: none;
  width: 100%;
}

#root .empty-state-message p + p {
  margin-top: 0.75rem;
  font-size: 0.95rem;
  font-weight: normal;
  color: #666;
}

/* Specific styling for broken links empty state */
#root [class*="broken_links"] .empty-state-message,
div[class*="broken_links"] ~ .empty-state-message {
  background: linear-gradient(to bottom, #f7f9ff, #f0f4ff);
  border: 1px solid rgba(100, 149, 237, 0.1);
}

/* Remove hover animation for broken links empty state as well */
#root [class*="broken_links"] .empty-state-message:hover,
div[class*="broken_links"] ~ .empty-state-message:hover {
  /* box-shadow: 0 8px 24px rgba(100, 149, 237, 0.08); - removed */
}

/* Force empty state message to span full width in any container */
.empty-state-message {
  width: 100% !important;
  max-width: 100% !important;
  box-sizing: border-box !important;
}

/* Make all paragraphs inside use full width with no max-width */
.empty-state-message p {
  max-width: none !important;
  width: 100% !important;
  box-sizing: border-box !important;
}

/* Full width container for empty states */
.full-width-container {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  box-sizing: border-box !important;
}

/* Styling for disabled search when no products are available */
.detail-margin.no-products .search-input-wrapper,
.detail-margin-simple.no-products .search-input-wrapper {
  opacity: 0.6 !important;
  pointer-events: none !important;
  cursor: not-allowed !important;
  background-color: #f0f0f0 !important;
  border-color: #ddd !important;
}

.detail-margin.no-products .search-input-box,
.detail-margin-simple.no-products .search-input-box {
  background-color: transparent !important;
  color: #999 !important;
  pointer-events: none !important;
  cursor: not-allowed !important;
}

.detail-margin.no-products .search-input-icon-btn,
.detail-margin-simple.no-products .search-input-icon-btn {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
  background-color: transparent !important;
  pointer-events: none !important;
}

/* Disable filter sections when no products are available */
.detail-margin.no-products .filter-section,
.detail-margin-simple.no-products .filter-section {
  opacity: 0.6 !important;
  pointer-events: none !important;
  display: none;
}

.detail-margin.no-products .segmented-container .ant-btn,
.detail-margin-simple.no-products .segmented-container .ant-btn {
  background-color: #f0f0f0 !important;
  color: #999 !important;
  border-color: #ddd !important;
  box-shadow: none !important;
  cursor: not-allowed !important;
}

/* Specific styling to hide filters for broken links tab */
.detail-margin-simple .filter-section {
  padding: 0 16px;
  margin-top: 20px;
}

/* Hide filter section only for broken links tab */
.detail-margin.broken_links .filter-section,
.detail-margin-simple.broken_links .filter-section {
  display: none !important;
} 