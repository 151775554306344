.stay-up-to-date-wrapper {
  max-width: 45rem !important;
  margin: auto;
  padding: auto;
  flex-direction: column;
  /* Stack components vertically */
  justify-content: center;
  align-items: center;
  scroll-behavior: unset !important;
  position: relative;
  /* scroll-behavior: smooth; */
  /* -webkit-scroll-behavior: smooth; */
  /* height: 100vh; Adjust as needed */
}

/* @media screen and (max-width:600px){
.stay-up-to-date-wrapper {
  scroll-behavior:smooth;
}
} */

/* .landing-mt-53 {
  margin-top: 53px;
} */

.loader-container {
  text-align: center;
}

.component-box {
  background-color: #fff;
  /* border: 1px solid #ccc; */
  border: 1px solid var(--grays-light);
  padding: 10px;
  margin: 10px 0px 10px 0px;
  border-radius: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  height: auto;
  justify-content: center;
}

.component-item {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 10px;
  /* border: 1px solid #e6e7eb; */
  border-radius: 0.5rem;
  /* background-color: #f9f9f9; */
  margin-bottom: -2%;
}

.component-text {
  display: grid;
  align-items: center;
  grid-template-columns: 10% 90%;
  margin-left: 0.5rem;
}

.component-name {
  margin-left: 8px;
  word-break: break-word;
}

.component-name-individual {
  margin-left: 8px;
  word-break: break-word;
}

.button-container {
  display: flex;
  gap: 10px;
  margin-left: 4%;
}

.feed-tab-media-wrapper svg {
  width: 25%;
  height: 25%;
  display: block;
  margin: auto;
  align-items: center;
  bottom: -140px;
}

.alertText {
  text-align: center;
  padding-top: 40px;
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 15px;
}

.alertTextTitle {
  text-align: center;
  color: #b41b1b;
  font-weight: bold;
  font-size: 16px;
  /* padding: 0px 20px 20px 20px;
    margin-bottom:0px; */
}

.landing-wrapper {
  padding-top: 1px;
}

@media screen and (max-width: 600px) {
  .component-text {
    /* margin-left: 8px; */
    word-break: break-word;
    width: 100%;
    margin-bottom: 3%;
    display: grid;
    align-items: center;
    grid-template-columns: 5% 100%;
    margin-left: 0
  }

  .component-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    /* border: 1px solid #e6e7eb; */
    border-radius: 0.5rem;
    /* background-color: #f9f9f9; */
    margin-bottom: 0%;
  }

  .component-name {
    margin-left: 8px;
    word-break: break-word;
    width: 70% !important;
  }

  .component-name-individual {
    margin-left: 8px;
    word-break: break-word;
    /* width: 70% !important; */
  }

  .individual-outer-container {
    display: flex;
    justify-content: center;
  }

  .button-container {
    display: flex;
    gap: 10px;
    margin-left: 0%;
  }
}

@media screen and (max-width: 680px) {
  .stay-up-to-date-wrapper {
    width: 100%;
    overflow-x: hidden;
  }

  .individual-outer-container {
    width: 100%;
    padding: 0;
  }

  .individual-outer-container .row {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    width: 100%;
    row-gap: 12px;
  }

  .individual-outer-container .row>[class*='col-'] {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0;
  }

  /* Add padding only between columns */
  .individual-outer-container .row>[class*='col-']:nth-child(odd) {
    padding-right: 6px;
  }

  .individual-outer-container .row>[class*='col-']:nth-child(even) {
    padding-left: 6px;
  }
}

.outline-drag {
  margin-right: 2%;
  vertical-align: text-bottom;
}

/* Add to your CSS file */
.stay-up-to-date-wrapper {
  /* touch-action: none; */
}

[data-rbd-draggable-context-id] {
  user-select: none !important;
  -webkit-user-select: none !important;
}

.dragging {
  pointer-events: auto !important;
}

@media screen and (max-width: 767px) {
  .landing-mt-53 {
    margin-top: 0px;
  }
}