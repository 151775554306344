.dashboard-grid-items-grid { 
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 25px;
	grid-row-gap: 25px;
	padding-bottom: 2rem;
}

.controls {
	padding-top: 1.25rem;
	display: grid;
	/* grid-template-columns: repeat(auto-fill, 350px); */
	grid-template-columns: 1fr;
	grid-column-gap: 25px;
	grid-row-gap: 25px;
	padding-bottom: 2rem;
	padding-left: 0px;
    padding-right: 0px;
}

.min-range {
	color: grey;
	margin-top: 2.8%;
	font-size: 14px;
	padding-right: 5px;
}
.max-range {
	color: gray;
	margin-top: 2.7%;
	font-size: 14px;
	float: right;
	margin-left: 3%;
}

.push-btn {
	background-color: black;

	color: #fff;
	margin-right: 3%;
	border: 2px solid black;
	border-radius: 10px;
	cursor: pointer;

	outline: none;
	/* padding: 12px; */

	font-size: 1.25rem;
	width: 200px;
	margin-bottom: 25px;

	padding: 8px;
}
.preview-btn {
	background-color: black;

	color: #fff;
	margin-left: 3%;
	border: 2px solid black;
	border-radius: 10px;
	cursor: pointer;

	outline: none;
	padding: 12px;

	font-size: 1.25rem;
	width: 200px;
}
.push-btn:disabled {
	background-color: lightgrey;

	color: #0c0a0a;
	text-align: center;

	border: 1px solid #080606;
	border-radius: 10px;
	cursor: not-allowed;

	outline: none;
	padding: 12px;
	margin-bottom: 25px;
	font-size: 1.25rem;
	width: 200px;
}
.spin-btn {
	background-color: lightgrey;

	color: #0c0a0a;
	text-align: center;
	margin-right: 3%;

	width: 200px;
	border: 1px solid #080606;
	border-radius: 10px;
	cursor: not-allowed;

	outline: none;
	padding: 12px;
	margin-bottom: 25px;
	font-size: 1.25rem;
}
@media (max-width: 767.98px) {
	.controls {
		justify-content: center;
		padding-top: 0px;
	}
	.push-btn-div {
		text-align: center;
		padding-bottom: 2rem;
	}
}

.controls-tab-row-queries{
	margin-top: 2%;
	width:80%;
	margin-left: 4%;

}
.custom-category-queryInput{
	width: 100%;
	box-sizing: border-box;
	border:none;
	outline: none;
	background-color: #fff;
	margin: auto;
	height: 50px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	border-radius: 15px;
	overflow: hidden;
	margin-bottom: 10px;
}
.custom-category-functions{
	float: right;
	margin-right: 2%;
	justify-content: space-between;
}
.section-heading {
    font-size: 1.25rem;
    font-weight: 600;
    color: #111827;
    margin: 28px 0 20px 0;
}

@media only screen and (max-width: 650px) {
	.section-heading {
		font-size: 1.125rem;
		 margin: 20px 0 15px 0;
		}

		.dashboard-grid-items-grid { 
			grid-template-columns: 1fr;
			padding-bottom: 0px;
			padding-bottom: 1rem;
		}

		/* .controls { 
			grid-template-columns: 1fr;
		} */

		[data-rbd-droppable-context-id="0"] {
			grid-template-columns: 1fr !important;
		}
}

.category-grid{
	display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 25px;

                    padding-top: 1.25rem;
                    padding-bottom: 2rem;
}

/* Controls tabs left alignment */
.ant-tabs.ant-tabs-card {
    width: 100%;
    margin-bottom: 0;
    padding-left: 0;
}

.ant-tabs.ant-tabs-card > .ant-tabs-nav {
    margin: 0 !important;
    padding: 0 !important;
}

/* Remove padding that might be causing the misalignment */
.dashboard-container {
    padding-left: 0;
    padding-right: 0;
}

.details {
    padding: 0;
}

/* Correct tabs left alignment */
.ant-tabs .ant-tabs-nav .ant-tabs-nav-list {
    justify-content: flex-start !important;
    width: 100%;
    padding: 0;
    margin: 0;
}

/* Adjust tab styling for proper alignment and spacing */
.ant-tabs .ant-tabs-tab {
    padding: 12px 0 !important;
    margin: 0 24px 0 0 !important;
    font-weight: 400;
    font-size: 16px;
}

.ant-tabs-tab { 
	background: transparent !important; 
	border: none !important;
	/* Add a transparent border-bottom that takes up space but isn't visible */
	border-bottom: 3px solid transparent !important;
}

.ant-tabs-ink-bar { 
	height: 5px !important;
}

.ant-tabs-tab.ant-tabs-tab-active { 
	border: none !important;
    border-bottom: 3px solid black !important;
}

/* Adding specific styling for the card type tabs */
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    margin-bottom: 0 !important;
    border-bottom: 3px solid transparent !important;
    transition: border-color 0.3s;
    background: transparent !important;
    border: none !important; 
    border-radius: 0 !important;
}

/* Style for active tab in card style to prevent layout shift */
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
    border-bottom: 3px solid black !important;
    margin-bottom: 0 !important;
    font-weight: 500;
}

/* Ensure black bottom border matches the tab width */
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: black;
}

/* Prevent ink bar from causing layout shift */
.ant-tabs-nav::before {
    border-bottom: 0 !important;
}

/* Ensure row has no extra margins or padding */
.dashboard-container .row {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
}

/* Dashboard mobile hide */
.dashboard-mobile-hide { 
	padding-bottom: 12px;
	font-size: 2rem;
}

@media (max-width: 768px) {
    .category-grid {
        display: block; /* Change to block or any other desired display property for mobile */
        grid-template-columns: auto; 
grid-column-gap:40px;
        grid-gap: 20px; /* Adjust grid gap for mobile if needed */
        /* padding: 1rem; Adjust padding for mobile if needed */
    }
	.category-grid > * {
        margin-bottom: 2rem; /* Adjust the margin-bottom value for the desired vertical spacing */
    }
}
@media (max-width: 1230px) {
	.ant-radio-group{
	display: flex;
    flex-wrap: wrap;
	gap: 5px !important;
	}
}

@media screen and (max-width: 400px) {
	.ant-radio-group {
	  flex-direction: column !important;
	  gap: 10px !important;
	}
  }

.content-type-radios{
	margin-top: 1%;
}
.ant-radio-group {
	display: flex;
	flex-direction: row;
	gap:0px;
}
/* Your custom styles to override the specific CSS */
.ant-radio-button-wrapper:not(:first-child)::before {
    position: static; /* Or any other appropriate value to override the 'absolute' position */
    inset-block-start: 0;
    inset-inline-start: 0;
    width: 0;
    height: 0;
    padding-block: 0;
    padding-inline: 0;
    background-color: transparent; /* Or the desired background color */
    transition: none; /* Remove the transition if not needed */
    content: none; /* Remove the content if not needed */
}
.ant-radio-button-wrapper-checked:not(:where(.css-dev-only-do-not-override-1nimcbs).ant-radio-button-wrapper-disabled) {
/* margin:2%; */
white-space: nowrap;
}

/* Info tooltip icon styling */
.info-tooltip {
    height: 14px;
    width: 14px;
    color: #6B7280;
    cursor: pointer;
    transition: color 0.2s ease;
}

.info-tooltip:hover {
    color: #2446f0;
}

.createButton{
margin-left: 1%;
background-color: black;
color: #fff;
border-color: black;
}
.radioButton {
border-radius: 4px;
border-left: 4px;
border: none;
outline: none;
}
.radioGroup {
border: none;
outline: none;
}
.colorControls {
	padding: 8px;
	border: 1px solid #ccc;
	border-radius: 4px;
	/* margin-left: 10%; */
}

.brand-modal-specing{
	gap: 5%;
}

.p-4 {
	padding: 4%;
}

.mt-minus2 {
	margin-top: -2%;
}

.radio-group {
	border: none;
	outline: none;
}

.custom-categories {
	margin-left: 1%;
	background-color: #414141;
	color: #fff;
	border-color: #414141;
}

.error-msg {
	color: red;
}

.font-1 {
	font-size: 1rem;
	color:#000
}

.tag-box {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

.outline-indicator {
	margin-left: -2%;
	margin-right: 1.5%;
}

.ml-2 {
	margin-left: 2%;
}

.mr-2 {
	margin-right: 2% !important;
}

.default-subcategory {
	font-size: 15px;
	margin-bottom: 10px;
}

.default-input-category {
	width: 88%;
	margin-bottom: 8%;
}

.mb-8 {
	margin-bottom: 8%;
}

.color-preview-tab{
	display: flex;
	flex-direction: column;
	/* gap: 12px; */
}

.product-bank-filter {
	margin-top: 10px;
}

.solid-bg-button, .solid-bg-button:hover{
	background-color: #000 !important;
	color: #fff !important;
	border: none;
}

.color-preview-tab .active {
	background: #3b3b3b !important;
	color: #fff !important;
}

/* 
 * BRAND DESIGN: Toggle Components
 * 
 * This is the official toggle design for the application:
 * - Clean, bordered container with subtle rounded corners (8px radius)
 * - No visible dividers between options
 * - Black background for the selected option with white text
 * - Consistent padding and spacing
 * - Smooth transitions between states
 * - Proper hover states for better UX
 * - Width automatically adjusts to content rather than stretching
 *
 * This styling should be used for all toggle components throughout the app
 * for visual consistency with the brand design system.
 */

/* Ant-Segmented styling */
.ant-segmented {
    background: #f5f5f7 !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px !important;
    padding: 2px !important;
    transition: all 0.2s ease;
    box-sizing: border-box !important;
    width: auto !important;
    overflow: hidden !important; /* Ensure no overflow */
}

.ant-segmented .ant-segmented-group {
    display: flex !important;
    border: none !important;
}

.ant-segmented .ant-segmented-item {
    color: #6B7280 !important;
    background-color: transparent !important;
    border-radius: 6px !important;
    font-weight: 500;
    transition: all 0.2s ease;
    padding: 5px 0 !important; /* Vertical padding only */
    text-align: center !important;
    min-width: 60px !important; /* Ensure consistent width */
    border: none !important; /* Remove borders between items */
}

/* Remove any dividers or borders between segmented items */
.ant-segmented .ant-segmented-item::before,
.ant-segmented .ant-segmented-item::after {
    display: none !important;
    content: none !important;
    border: none !important;
}

/* Add specific styling for the Show/Hide text to ensure centered alignment */
.ant-segmented .ant-segmented-item-label {
    display: block !important;
    text-align: center !important;
    padding: 0 16px !important; /* Horizontal padding here */
    width: 100% !important;
}

.ant-segmented .ant-segmented-item:hover {
    color: #111827 !important;
}

.ant-segmented .ant-segmented-item-selected {
    background-color: black !important;
    color: #ffffff !important;
    font-weight: 600;
}

.ant-segmented .ant-segmented-item-selected:hover {
    color: #ffffff !important;
}

.ant-segmented .ant-segmented-thumb {
    background-color: black !important;
    border-radius: 6px !important;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
}

/* Show/Hide toggle in dashboard grid items */
.dashboard-grid-item .ant-segmented {
    min-width: 140px !important;
    margin-top: 0 !important;
    align-self: flex-start !important;
}

/* Remove previous duplicate definition that may cause conflicts */
.dashboard-grid-item .ant-radio-group {
    width: auto !important;
    min-width: auto !important;
}

/* Controls tabs specific styling */
.controls-tabs {
    padding-left: 0;
    margin-left: 0;
}

.controls-tabs .ant-tabs-nav {
    padding-left: 0 !important;
    margin-left: 0 !important;
}

.controls-tabs .ant-tabs-nav-list {
    padding-left: 0 !important;
    margin-left: 0 !important;
}

.controls-tabs .ant-tabs-tab {
    padding-left: 0 !important;
}

.controls-tabs .ant-tabs-tab:first-child {
    margin-left: 0 !important;
    padding-left: 0 !important;
}

/* Fix content alignment within tab panels */
.controls-tabs .ant-tabs-content-holder {
    padding-left: 0;
}

.controls-tabs .ant-tabs-tabpane {
    padding-left: 0 !important;
}

/* Align content with tabs */
.dashboard-container .controls-container {
    padding-left: 0;
    margin-left: 0;
}

.dashboard-container .row {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
}

.dashboard-container .account-social-logins-heading {
    padding-left: 0;
    margin-left: 0;
}

.dashboard-container .dashboard-grid-items-grid {
    padding-left: 0;
    margin-left: 0;
}

/* Ensure no extra padding in tab content */
.ant-tabs-content {
    padding-left: 0 !important;
    margin-left: 0 !important;
}

.ant-tabs-tabpane-active {
    padding-left: 0 !important;
    margin-left: 0 !important;
}

/* Ensure headings in tab content align with tabs */
.account-social-logins-heading {
    font-size: 1.25rem;
    font-weight: 600;
    color: #111827;
    padding-left: 0 !important;
    margin-left: 0 !important;
    margin-top: .25rem;
    margin-bottom: 20px;
    text-align: left;
}

/* Custom tab content panel styles */
.ant-tabs-tabpane.ant-tabs-tabpane-active {
    padding: 0 !important;
    margin: 0 !important;
}

/* Target the specific landing page section elements */
.account-social-logins-heading {
    padding-left: 0 !important;
    margin-left: 0 !important;
    text-align: left;
}

/* Make sure all containers inside tab content have no left padding/margin */
.controls-tabs .ant-tabs-content-holder > div > div > div {
    padding-left: 0 !important;
    margin-left: 0 !important;
}

/* Target the dashboard grid items specifically */
.dashboard-grid-items-grid {
    padding: 0 !important;
    margin: 0 !important;
}

/* Fix any container padding */
.controls-container {
    padding: 0 !important;
    margin: 0 !important;
}

/* Extra specificity to ensure no padding */
.controls-tabs .ant-tabs-content-holder .controls-container {
    padding: 0 !important;
    margin: 0 !important;
}

/* Tab content adjustments */
.ant-tabs .ant-tabs-content {
    padding-left: 0 !important;
    margin-left: 0 !important;
    padding-top: 16px; /* Add top padding to all tab content */
}

.ant-tabs-content-holder {
    padding-left: 0 !important;
    margin-left: 0 !important;
}

/* Make sure the tab panel itself has no padding */
.ant-tabs-tabpane {
    padding-left: 0 !important;
    margin-left: 0 !important;
}

/* Target the container row of tab content */
.controls-container > .row {
    padding-left: 0 !important;
    margin-left: 0 !important;
}

/* Reset padding for only the tab content containers, not their children */
.ant-tabs-content-holder {
    padding-left: 0 !important;
    margin-left: 0 !important;
}

/* Make sure the tab panel itself has no padding but its immediate children do */
.ant-tabs-tabpane {
    padding-left: 0 !important;
    margin-left: 0 !important;
}

/* Add specific padding to the container boxes */
.dashboard-grid-items-grid {
    padding-left: 0 !important;
    margin-left: 0 !important;
}

/* Target the individual grid boxes with gray background */
.dashboard-grid-item {
    background-color: #f5f5f7;
    border-radius: 16px;
    padding: 1.75rem;
    position: relative;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.03);
    border: 1px solid rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    overflow: hidden;
    justify-content: space-between; /* Pushes content to top and bottom */
}

/* Add the subtle top border that's initially hidden */
.dashboard-grid-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(90deg, #2446f0, #3b64f6);
    opacity: 0;
}

/* Add padding to text and elements inside grid items */
.dashboard-grid-item p {
    font-size: 0.875rem;
    font-weight: 500;
    color: #6B7280;
    letter-spacing: 0.02em;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

/* Buttons and interactive elements should have proper spacing */
.dashboard-grid-item button,
.dashboard-grid-item .ant-segmented {
    margin-left: 0 !important;
    margin-top: auto;
}

/* Remove any unnecessary double padding */
.dashboard-grid-item:first-child button {
    margin-left: 0 !important;
}

/* Ensure section headings are properly aligned */
.controls-container > p.account-social-logins-heading {
    padding-left: 0 !important;
    margin-left: 0 !important;
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 1.25rem;
    font-weight: 600;
    color: #111827;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .dashboard-grid-items-grid {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    
    .dashboard-grid-item {
        padding: 1.5rem;
    }
}

/* Animation for new elements */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.dashboard-grid-item {
    animation: fadeIn 0.4s ease-out;
}

/* Add consistent button styling to match account buttons */
button.controlsButton,
a.controlsButton,
.ant-btn.controlsButton {
    background-color: black !important;
	color: #fff !important;
    border: none !important;
    border-radius: 8px !important;
    padding: 0.75rem 1.25rem !important;
    font-weight: 500 !important;
    transition: all 0.2s ease !important;
    height: auto !important;
    font-size: 0.9375rem !important;
}

button.controlsButton:hover,
a.controlsButton:hover,
.ant-btn.controlsButton:hover {
    background-color: #333333 !important;
    transform: translateY(-1px) !important;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
	color: #fff !important;
    border-color: transparent !important;
}

button.controlsButton:active,
a.controlsButton:active,
.ant-btn.controlsButton:active {
    transform: translateY(0) !important;
}

/* Consistent styling for standard buttons */
.controlsButton {
    background-color: black;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 0.75rem 1.25rem;
    font-weight: 500;
    transition: all 0.2s ease;
    height: auto;
    font-size: 0.9375rem;
}

.controlsButton:hover {
    background-color: #333333;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.controlsButton:active {
    transform: translateY(0);
}

/* Modal styling */
.ant-modal-content {
    border-radius: 16px !important;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12) !important;
}

.ant-modal-header {
    background-color: #f5f5f7 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
    padding: 16px 24px !important;
}

.ant-modal-title {
    font-size: 1.25rem !important;
    font-weight: 600 !important;
    color: #111827 !important;
}

.ant-modal-body {
    padding: 24px !important;
}

.ant-modal-footer {
    /* border-top: 1px solid rgba(0, 0, 0, 0.06) !important; */
    padding: 16px 24px !important;
}

.ant-modal-footer button.ant-btn-primary {
    background-color: black !important;
    border-color: black !important;
    color: white !important;
}

.ant-modal-footer button.ant-btn-primary:hover {
    background-color: #333333 !important;
    border-color: #333333 !important;
}

.modal-title {
    font-weight: 600 !important;
    padding: 0 !important;
    margin: 0 !important;
    text-align: left;
    display: flex;
    align-items: center;
    font-size: 1.1rem !important;
}

/* Cancel button styling */
button.controlsCancelButton,
a.controlsCancelButton,
.ant-btn.controlsCancelButton {
    background-color: #f5f5f7 !important;
    color: #111827 !important;
    /* Remove the border */
    border: none !important;
    border-radius: 8px !important;
    padding: 0.75rem 1.25rem !important;
    font-weight: 500 !important;
    transition: all 0.2s ease !important;
    height: auto !important;
    font-size: 0.9375rem !important;
}

button.controlsCancelButton:hover,
a.controlsCancelButton:hover,
.ant-btn.controlsCancelButton:hover {
    background-color: #e5e5e5 !important;
    color: #000000 !important;
    /* No transform to prevent size changes */
    transform: none !important;
}

button.controlsCancelButton:active,
a.controlsCancelButton:active,
.ant-btn.controlsCancelButton:active {
    background-color: #d9d9d9 !important;
    transform: none !important;
}

/* Brand Color button - completely separate styling */
.brandColorButton {
    /* Basic button styles - don't include background or color as they come from inline style */
    border: none !important;
    border-radius: 8px !important;
    padding: 0.75rem 1.25rem !important;
    font-weight: 500 !important;
    transition: all 0.2s ease !important;
    height: auto !important;
    font-size: 0.9375rem !important;
    cursor: pointer !important;
}

.brandColorButton:hover {
    transform: translateY(-1px) !important;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
    opacity: 0.9 !important;
}

.brandColorButton:active {
    transform: translateY(0) !important;
    opacity: 1 !important;
}

/* Hex code pill styling - base styles */
.color-hex-pill {
    display: inline-block;
    border-radius: 999px; /* Large value for pill shape */
    padding: 0.3rem 0.8rem;
    font-family: 'Menlo', 'Monaco', 'Courier New', monospace;
    font-size: 0.875rem;
    letter-spacing: 0.05em;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    font-weight: 600;
    transition: all 0.2s ease;
    position: relative;
    overflow: hidden;
}

/* For light colored backgrounds - use dark container with white text */
[style*="background-color: #f"] .color-hex-pill,
[style*="background-color: #e"] .color-hex-pill,
[style*="background-color: #d"] .color-hex-pill,
[style*="background-color: #c"] .color-hex-pill,
[style*="background-color: #b"] .color-hex-pill,
[style*="background-color: #a"] .color-hex-pill,
[style*="background-color: rgb(2"] .color-hex-pill,
[style*="background-color: rgb(1"] .color-hex-pill {
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.2);
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
}

/* For dark colored backgrounds - use light container with dark text */
[style*="background-color: #0"] .color-hex-pill,
[style*="background-color: #1"] .color-hex-pill,
[style*="background-color: #2"] .color-hex-pill,
[style*="background-color: #3"] .color-hex-pill,
[style*="background-color: #4"] .color-hex-pill,
[style*="background-color: #5"] .color-hex-pill,
[style*="background-color: #6"] .color-hex-pill,
[style*="background-color: #7"] .color-hex-pill,
[style*="background-color: #8"] .color-hex-pill,
[style*="background-color: #9"] .color-hex-pill,
[style*="background-color: rgb(0"] .color-hex-pill {
    background-color: rgba(255, 255, 255, 0.75);
    color: #222;
    border: 1px solid rgba(0, 0, 0, 0.1);
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);
}

/* Add a subtle effect on hover */
.brandColorButton:hover .color-hex-pill {
    transform: scale(1.03);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Fallback pill style in case none of the selectors match */
.color-hex-pill {
    background-color: rgba(0, 0, 0, 0.2);
    color: inherit;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

/* Help text for color picker */
.color-help-text {
    display: block;
    font-size: 0.75rem;
    color: #6B7280;
    margin-top: -0.75rem;
    margin-bottom: 0.75rem;
    /* font-style: italic; */
    font-weight: 400;
    letter-spacing: 0.01em;
    line-height: 1.2;
}

/* Color picker modal styling */
.color-picker-modal {
    overflow: hidden;
}

.color-picker-modal .ant-modal-content {
    background-color: white;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
}

.color-picker-modal .ant-modal-header {
    padding: 20px 24px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    position: relative;
    height: 72px;
    display: flex;
    align-items: center;
    background-color: transparent !important; /* Remove background color */
}

.color-picker-modal .ant-modal-title {
    font-weight: 600;
    font-size: 1.25rem;
    color: #111827;
    padding: 0;
    margin: 0;
    width: calc(100% - 60px); /* Leave more space for the X button */
    position: relative;
    z-index: 1;
}

.color-picker-modal .ant-modal-close {
    top: 16px;
    right: 16px;
    position: absolute;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 50%;
    transition: background-color 0.2s ease;
    z-index: 10;
}

.color-picker-modal .ant-modal-close:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.color-picker-modal .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #6B7280;
    height: 40px;
    width: 40px;
    line-height: 40px;
}

.color-picker-modal .ant-modal-body {
    padding: 20px 24px 24px !important;
    background-color: transparent !important;
}

.color-picker-modal .ant-modal-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    padding: 16px 24px;
    margin-top: 0;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    background-color: transparent !important;
}

/* Modal buttons - completely normalized sizing and behavior */
.color-picker-modal .ant-btn {
    min-width: 90px !important;
    width: 90px !important;
    height: 36px !important; /* Reduced consistent height */
    line-height: 36px !important; /* Matching line height */
    padding: 0 !important;
    margin: 0 !important;
    box-sizing: border-box !important;
    text-align: center !important;
    display: inline-flex !important; /* Use inline-flex for consistent sizing */
    align-items: center !important;
    justify-content: center !important;
    transform: none !important;
    border: none !important;
    border-radius: 8px !important;
    font-size: 0.9375rem !important;
    font-weight: 500 !important;
    transition: background-color 0.2s ease !important;
    position: relative !important;
    overflow: hidden !important;
    box-shadow: none !important;
    outline: none !important;
}

/* Override all states to ensure consistent height */
.color-picker-modal .ant-btn:hover,
.color-picker-modal .ant-btn:active,
.color-picker-modal .ant-btn:focus,
.color-picker-modal .ant-btn:focus-visible {
    transform: none !important;
    height: 36px !important; /* Same height as base */
    line-height: 36px !important;
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: none !important;
    outline: none !important;
    background-color: #333333 !important;
    color: white !important;
}
.controlsButton {
    background-color: #333333 !important;
    color: white !important;
  }

/* Primary button (Save) */
.color-picker-modal .ant-btn-primary {
    background-color: black !important;
    color: white !important;
}

.color-picker-modal .ant-btn-primary:hover,
.color-picker-modal .ant-btn-primary:active,
.color-picker-modal .ant-btn-primary:focus {
    background-color: #333333 !important;
    color: white !important;
}

/* Default button (Cancel) */
.color-picker-modal .ant-btn-default {
    background-color: #f5f5f7;
    color: #111827;
    margin-right: 10px !important;
}

.color-picker-modal .ant-btn-default:hover,
.color-picker-modal .ant-btn .controlsButton:active,
.color-picker-modal .ant-btn-default:focus {
    background-color: #000000 !important;
    color: #ffffff !important;
}

/* Ensure consistent footer spacing */
.color-picker-modal .ant-modal-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    padding: 16px 24px;
    margin-top: 0;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    background-color: transparent !important;
    height: 68px !important; /* Fixed height footer */
    box-sizing: border-box !important;
    align-items: center !important;
}

/* Ensure the color picker fits nicely */
.color-picker-modal .rcp {
    width: 100% !important;
    max-width: 100%;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
}

/* Ensure proper spacing between tabs and first content section when no title */
.ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane > .dashboard-grid-item:first-child,
.ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane > div:first-child > .dashboard-grid-item:first-child {
    margin-top: 24px;
}

/* Ensure all sections have consistent spacing */
.dashboard-grid-item {
    margin-bottom: 16px;
}
