.wrapper-div {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust min width as needed */
  /* margin-left: 2%; */
  /* margin-right: 2%; */
  row-gap: 8px;
  column-gap: 8px;
  /* margin:2% */
  /* gap: 16px; Space between the cards */
}

.extra-carousel-card {
  background-size: cover;
  background-position: center;
  border-radius: 8px; /* Optional: adds rounded corners */
  height: 150px; /* Set a fixed height for the cards */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Positioning for text overlay */
  color: white; /* Text color */
}

.extra-carousel-card-text {
  position: absolute; /* Position text absolutely within the card */
  bottom: 10px; /* Adjust position as needed */
  left: 10px; /* Adjust position as needed */
  background: rgba(0, 0, 0, 0.5); /* Optional: adds background to text for readability */
  padding: 5px; /* Optional: adds padding */
  border-radius: 4px; /* Optional: adds rounded corners to text background */
}

  .fullWidthButton {
    width: 100%;
    padding: 16px 20px;
    background-color: rgba(255, 255, 255, 0.9); /* Light white background */
    color: black;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ddd; /* Light border */
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for card effect */
    margin: 10px 0;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .fullWidthButton:hover {
    background-color: rgba(255, 255, 255, 1); /* Darker background on hover */
    transform: translateY(-2px); /* Lift effect on hover */
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15); /* Slightly stronger shadow on hover */
  }
  
  .arrowIcon {
    font-size: 16px;
  }

.hr-wrapper{
  color: "grey";
  width:'100%';
  margin-left:'0px';
  margin-right:'0px';
  max-width:'none'
}
  


  .carousel {
    display: flex;
    /* overflow-x: auto; */
  }
  .product-card {
    /* width: 200px; Maintain the width */
    /* margin: 10px; */
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    /* overflow: hidden; */
    text-align: center;
    position: relative;
  }

  .react-loading-skeleton{
    line-height: 2;
  }
  
  .product-image {
    height: 200px; /* Keep the height */
    background-size: cover; /* Ensure the image covers the area */
    background-position: center; /* Center the image */
    position: relative; /* Set to relative to position text */
    /* overflow: hidden; Hide overflow for the pseudo-element */

    border-radius: 8px;
    overflow: hidden;
  }
  
  /* .prod */
  
  .product-text {
    position: absolute; /* Absolutely position the text */
    bottom: 0px; /* Position the text at the bottom */
    left: 10px; /* Add some left padding */
    color: white; /* Text color for visibility */
    font-weight: bolder;
    /* background-color: rgba(0, 0, 0, 0.5); Semi-transparent background for readability */
    padding: 5px 10px; /* Padding around the text */
    border-radius: 4px; /* Optional: rounded corners */
  }
  
  .product-details {
    padding: 10px; /* Maintain padding for details */
  }
  
  .product-button {
    padding: 8px 16px; /* Maintain button padding */
    margin-top: 10px;
    background-color: #007BFF; /* Button color */
    color: #fff; /* Button text color */
    border: none; /* No border */
    border-radius: 4px; /* Rounded corners for button */
    cursor: pointer; /* Pointer cursor on hover */
  }
  .wrapper-heading{
    color:black;
    font-weight: bolder;
    /* margin-left:2%; */
    width: 100%;
    text-align: center;
  }

  .product-image::before {
    content: ''; /* Necessary for the pseudo-element */
    position: absolute; /* Position it absolutely within the parent */
    bottom: 0; /* Align it to the bottom */
    left: 0; /* Align it to the left */
    right: 0; /* Align it to the right */
    height: 50%; /* Adjust height as needed */
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent); /* Gradient from black to transparent */
    pointer-events: none; /* Allow clicks to pass through */
  }


  .component-name-individual {
    margin-right: auto;
    color: black;
    line-height: 1.3;
    padding-right: .5rem;
    margin-bottom: 0rem;
    font-weight: 700;
    font-size: 1.25rem;
    /* width: 75%; */
  }
  