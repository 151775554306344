.sliding-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 240px;
    height: 100vh;
    background-color: white;
    transform: translateX(-100%);
    transition: all 0.3s ease-in-out;
    z-index: 999;
    opacity: 0;
    pointer-events: none;
}

.mobile-header-share {
    background: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .mobile-header-share svg {
    font-size: 20px;
    color: #333;
  }

.sliding-sidebar.open {
    transform: translateX(0);
    opacity: 1;
    pointer-events: all;
}

.sliding-sidebar.dashboard {
    background-color: #f5f5f5;
    /* Different styling for dashboard */
}

.sliding-sidebar-content {
    height: 100%;
    overflow-y: auto;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

@media (min-width:767px) {



    .dashboard-content {
        transition: transform 0.3s ease-in-out;
        transform: translateX(0);
        /* Default state */
    }

    .content-shifted {
        width: calc(100% - 240px);
        transform: translateX(240px);
        /* Shifted state when sidebar is open */
    }

    /* Add specific styles for each type if needed */
    .sliding-sidebar.follower .header-nav-image {
        /* position: fixed; */
        /* top: 4%; */
        /* left: 5%; */
    }

    .sliding-sidebar.follower .header-nav-image-close {
        position: fixed;
        top: 4%;
        left: 75%;
        padding: 5px;
    }


}

.sliding-sidebar.follower.open {
    /* No need for visibility changes */
}

.sliding-sidebar.dashboard .sliding-sidebar-content {
    /* Dashboard-specific styles */
}

/* Responsive styles */
@media (max-width: 767px) {
    .sliding-sidebar {
        width: 100%;
        height: 60px;
        transform: none;
        opacity: 1;
    }

    .header-wrapper {
        justify-content: end;
    }

    .content-shifted {
        margin-left: 0;
        /* Don't shift content on mobile */
    }

    .header-nav-image-close {
        display: none;
    }

    .header-nav-image {
        right: 13px;
        top: 5px;
        z-index: 100000;
        position: fixed;
        background-color: #ffffff;
    }
    .mobile-header-share {
        display: none;
      }
}

.sliding-sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Hide the "b" logo on mobile */
@media (max-width: 767px) {
  .sliding-sidebar-header .header-left img {
    display: none;
  }
  .mobile-share-icon {
    position: fixed;
    top: 10px;
    right: 114px;
    z-index: 1000;
}
}

.hamburger-button {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 24px;
}