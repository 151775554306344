/* Modern Menu Styling */

/* Style for the Menu Paper component (the container) */
.MuiMenu-paper {
  border-radius: 12px !important;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12) !important;
  border: 1px solid rgba(0, 0, 0, 0.05) !important;
  overflow: hidden !important;
  min-width: 200px !important;
  margin-top: 6px !important;
}

/* Menu list container */
.MuiMenu-list {
  padding: 6px !important;
}

/* Individual menu items */
.MuiMenuItem-root {
  border-radius: 8px !important;
  padding: 10px 12px !important;
  margin: 2px 0 !important;
  transition: background-color 0.2s ease !important;
  font-size: 14px !important;
  color: #333 !important;
  display: flex !important;
  align-items: center !important;
}

.MuiMenuItem-root:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

/* Menu icon styling */
.menu-icon {
  margin-right: 12px !important;
  font-size: 18px !important;
  color: #555 !important;
}

/* Style for menu item text */
.MuiMenuItem-root span {
  font-weight: 500 !important;
}

/* Focus state - more subtle */
.MuiMenuItem-root.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.04) !important;
} 