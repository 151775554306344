#slidingVertical input::placeholder {
    color: transparent;
  }
  
  #slidingVertical input::-webkit-input-placeholder {
    color: transparent;
  }
  
  #slidingVertical input::-moz-placeholder {
    color: transparent;
  }
  
  #slidingVertical input::-ms-input-placeholder {
    color: transparent;
  }
  
  #slidingVertical input:-moz-placeholder {
    color: transparent;
  }
  
  #slidingVertical input {
    font-size: 1rem;
  }
  
  #slidingVertical {
    position: relative;
    overflow: hidden;
    width: 300px; /* Adjust width as needed */
  }
  
  #slidingVertical span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    animation: rotatePlaceholders 15s linear infinite;
  }
  
  @keyframes rotatePlaceholders {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  
  .max-tags-text{
    font-size: 0.7rem;
    margin-top: -0.2%;
    margin-bottom: 1.2%;
    width: fit-content;
    margin-left: 0%;
    /* border:1px solid black */
  }
  .max-tags-text p{
   padding: 0px 5px;
  }

  .query-input {
    background-color: transparent !important;
  }

  .tag-box {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    color: black;
  }

  .errorMsg {
    color: rgb(241, 98, 98);
  }

  .max-tag {
    color: #3d3c3c;
  }

  .preview-search {
    background: #000;
    margin-top: -20%;
    margin-bottom: 7%;
    color: #fff;
    padding: 0.5rem 1rem;
  }

  .font-size-1 {
    font-size: .8rem;
    margin-left: 5px;
  }

  .social-icon-width {
    width: 96%;
    margin: auto;
  }

  .spin-integration {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
  }

  .search-btn-style {
    background-color: black;
    color: white;
    font-size: 0.8rem;
    margin-left: 1%;
  }

  .account-style {
    background-color: #fff;
    border: solid #fff 1px;
  }

  .searchbar-configure{
    margin: 2%;
    width: 96%;
    margin: 1rem auto;
  }

  .searchbar-placeholder-text{
    font-size: 1rem;
    margin-bottom: 0;
    padding: 7px 12px;
    border-radius: 12px;
    background-color: #fff;
  }

  .bg-grey{
    background-color: #f1f1f1 !important;
  }