.search-container {
  width: 100%;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: #f1f1f1;
  margin: auto;
  /* height: 75px; */
  height: 70px;
  /* display: flex; */
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 15px;
}

.search-container-div {
  width: 96%;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: #f1f1f1;
  margin: auto;
  /* height: 75px; */
  height: 70px;
  /* display: flex; */
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 15px;
}

.search-wrapper-input {
  border: none;
  outline: none;
  height: 100%;
  color: #000;
  font-size: 18px;
  border-radius: 50px;
  padding-left: 25px;
  width: 90%;
  float: left;
  background: #f1f1f1;
  /* height: 75px; */
  height: 70px;
  color: #000;
  font-size: 18px;
}

.search-wrapper-btn {
  border: none;
  padding-right: 15px;
  position: relative;
  float: right;
  cursor: pointer;

  font: 1.25rem "lucida sans", "trebuchet MS", "Tahoma";
  color: #000;
  text-transform: uppercase;
  border-radius: 50%;

  /* width: 5%; */
  background-color: #f1f1f1;
  height: 100%;
  margin-top: 0%;
}

.w-5 {
  width: 5%;
}

@media screen and (max-width: 1280px) {
  .search-wrapper-input {
    width: 85%;
  }

  .search-wrapper-btn {
    width: 15%;
  }
}

.search-hashtag-btn {
  /* border: 2px solid #404040; */
  border: solid #e6e6e6 1px;
  /* background-color: #404040; */
  background-color: #fff;
  position: relative;
  /* z-index:1; */
  /* color: #fff; */
  color: #404040;
  outline: none;
  cursor: pointer;
  font-size: 13px;
  border-radius: 10px;
  /* padding: 12px; */
  overflow: hidden;
  /* max-width: 200px; */
  white-space: nowrap;

  padding: 8px;
}

.search-hashtag-btn.active {
  border: 2px solid #404040;
  width: 100%;

  /* position: relative; */

  /* background-color: #fff; */
  background-color: #404040;
  display: inline-block;

  /* color: #404040; */
  color: #fff;
  outline: none;

  font-size: 13px;

  border-radius: 10px;
  /* padding: 12px; */
  padding: 8px;
  max-width: 300px;
}

.btn-container {
  display: flex;
  position: relative;


}

.btn-container .search-cross-btn {
  right: -6px;
  top: -6px;

  border-radius: 25%;

  font-size: 10px;
  z-index: 2;
  position: absolute;
  border: 1px solid #404040;
  background-color: #fff;
  color: #404040;
  display: none;
}

.search-hashtag-focus {
  border: 2px solid #404040;
  width: 100%;

  position: relative;

  background-color: #fff;
  display: inline-block;

  color: #404040;
  outline: none;

  font-size: 13px;

  border-radius: 10px;
  padding: 12px;
  max-width: 300px;
}

.search-hashtags,
.search-hashtags-scroll {
  height: fit-content;
  justify-content: flex-start;
}

.search-hashtags {
  vertical-align: middle;
  width: 100%;
  /* margin-top: 0.5rem; */
}

.search-hashtags-section-title {
  font-size: 1.1em;
  margin: 0;
  color: #000;
  display: block;

  margin-block-start: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.search-hashtags-scroll {
  display: flex;

  overflow: scroll;
  overflow-y: scroll;
  gap: 10px;
  -webkit-overflow-scrolling: touch;

  padding-top: .5rem;
}

.search-hashtags-scroll::-webkit-scrollbar {
  display: none;
}

.search-hashtag .search-hashtag:focus-within {
  border: 2px solid #404040;

}


.search-hashtag-btn:focus-within {

  cursor: none;
  pointer-events: inherit;

  background-color: #404040;
  color: #fff;
}

.search-hashtags-title-btn {
  margin-top: 2%;

  border: none;
  background-color: hwb(0 89% 9%);
  padding: 12px;
  border-radius: 10px;

  margin-right: 1%;
  color: black;
}

.search-hashtags-title-btn-active {
  margin-top: 2%;
  background-color: #404040;
  border: #404040;
  color: #fff;
  padding: 12px;
  border-radius: 10px;
  margin-right: 1%;
}

#search-buttons {
  overflow: auto;
  overflow-x: auto;
  overflow-y: hidden;

  color: black;
}


.search-alt-query {
  margin-top: 10px;
  color: #000;
}

.search-alt-query-btn {
  background-color: #fff;
  border: none;
  color: rgb(82, 82, 191);
  text-decoration: underline;

  width: fit-content;
  padding: 0;
}

img.card-img-top.creator-feed-card-video {
  height: 500px;
  margin-bottom: 7px;
}

@media (max-width: 767.98px) {
  .search-cross-btn {
    border-radius: 25%;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 1px;
    padding-bottom: 1px;
    line-height: normal;
    display: none;
  }
}

.search-elements {
  margin-top: 3%;
  margin-bottom: 3%;
  /* padding-left: 3%; */
  /* padding-right: 3%; */
}



.hashtag-filt-btn-wrpr {
  margin-top: 1rem;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  padding-bottom: 1px;
  border-bottom: .5px solid lightgrey;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.hashtag-filt-btn-wrpr {
  gap: 6px;
  overflow: auto;
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: unset;
}

.hashtag-filt-btn-wrpr::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 600px) {
  .hashtag-filt-btn-wrpr {
    gap: 6px;
    overflow: auto;
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: unset;
  }

  .search-elements {
    margin-top: 3%;
    margin-bottom: 6%;
    padding-left: 3%;
    padding-right: 3%;
  }



  img.card-img-top.creator-feed-card-video {
    height: 305px;
  }
}

.hashtag-filt-btn {
  color: black;
  background-color: white;
  border-radius: 7px;
  padding: 5px 12px;
  cursor: pointer;
  white-space: nowrap;

  font-size: 1rem;
  font-weight: 500;
}

.hashtag-filt-btn.active {
  /* border: solid #404040 1px; */
  /* color: #fff; */
  /* background-color: #404040; */
  white-space: nowrap;

  /* background-color: #404040; */
  color: black;
  border-bottom: 3px solid black;
  border-radius: 0px;
}

.hashtag-filt-btn.disabled {
  pointer-events: none;
  background-color: #616161;
  color: rgb(255, 255, 255);
}

.hashtag {
  color: black;
  background: #fff;
  text-decoration: none;
}


.search-preview-div {
  border: 1px solid #f1f1f1;
  border-radius: 15px;

}

.search-btn-width {
  width: 4rem;
}

@media (min-width:1440px) {
  .search-elements {
    padding: 0 2%;
  }

}

/* Stay Up to date Search */


.search-elements-stayuptodate {
  width: 100%;
  /* Use percentage width for better responsiveness */
  max-width: 45rem;
  /* Prevents the element from becoming too wide on large screens */
  margin: 2% auto;
  /* Maintains center alignment and vertical spacing */
}

/* Media queries for different screen sizes */
@media screen and (max-width: 768px) {
  .search-elements-stayuptodate {
    width: 100%;
    /* Slightly wider on medium screens */
    margin: 5% auto;
    /* Adjust vertical spacing */
    padding: 0;
    /* Reduce padding */
  }
}

@media screen and (max-width: 480px) {
  .search-elements-stayuptodate {
    width: 100%;
    /* Full width on small screens */
    margin: 7% auto;
    /* Increase vertical spacing */
    padding: 0;
    /* Minimal side padding */
  }

  .search-btn-width {
    width: 15%;
  }
}

/* Main Search bar */

.search-elements {
  margin-bottom: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3%;
  /* transition: max-width 0.5s ease-out; */
}

.search-container,
.search-hashtags {
  width: 100%;
  /* Add this to ensure the elements have a base width */
  max-width: 80rem;
  transition: max-width 1.5s ease-in-out;
}


.search-container-div-landing.search-container,
.search-container-div-landing.search-hashtags {
  max-width: 45rem;
}


@media (min-width:1056px) and (max-width:1416px) {

  .search-container,
  .search-hashtags {
    max-width: 60rem;
  }
}

@media (min-width:700px) and (max-width:1055px) {

  .search-container,
  .search-hashtags {
    max-width: 39.5rem;
  }
}