.no-links-msg {
  color: #404040;
  padding: 20px;
  margin-bottom: 0px;
}

/* .account-social-content-connect {
  border: 1px solid red;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  margin: 15px 0 50px 0;
  background-color: #fcfcfc;
  border: solid #e8e8e8 1px;
  width: 100%;
} */

.account-social-link-connect-row-cont .ant-row {
  /* flex-flow: nowrap; */
  width: 100%;
}

.account-image-wrapper {
  display: block
}

.account-social-link-connect-row-cont textarea {
  min-height: 100px;
  overflow: scroll;
}

.account-social-link-connect-row {
  /* display: flex; */
  /* padding: 15px; */
  cursor: pointer;
}

.account-social-link-connect-row-cont {
  gap: 12px;
  width: 100%;
}

.account-social-link-connect-row-cont p {
  margin-bottom: 0px;
  overflow-wrap: break-word;
}

.searchbar {
  width: 96%;
  margin: auto;
  display: flex;
  align-items: baseline;
}

.searchbar p {
  width: 100%;
  margin-right: auto;
  height: 47px;
}

.confirmation-dialog {
  justify-content: space-between;
  margin-top: 30px;
  transform: translateY(-20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.confirm-color {
  background-color: #000;
  color: #fff;
}

.confirmation-dialog p {
  color: #414141;
  text-align: center;
}

.social-link {
  width: 90%;
  margin: auto;
  align-content: center;
  text-align: center;
  margin-top: 3%;
  background: white;
  border: 1px solid #b6b3b3;
  border-radius: 1rem;
}

.active-social-links {
  width: 45px;
  /* Adjust size as needed */
  height: 45px;
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  /* border-radius: 30px;
   height: 45px;
   width: 45px; */
  font-size: 1.5rem;
  padding: 4px;
  border: none;
  -webkit-tap-highlight-color: transparent;
  /* padding-top: revert-layer; */

  position: relative;
}

.active-social-links svg {
  vertical-align: middle;
  object-fit: cover;
  /* pointer-events: none; */
}

@media screen and (max-width: 600px) {
  .editUploadButton {
    margin-top: 25%;
  }

  .searchbar input {
    margin-bottom: 2%;
  }

  .searchbar {
    display: block;
  }
}

.drag-indicator {
  /* .drag-indicator { */
  margin-right: 2%;
  vertical-align: text-bottom;
}

.ml-2 {
  margin-left: 2%;
}

.vertical-top {
  vertical-align: top;
}

.title-btn {
  margin-right: 2%;
  float: right;
  display: flex;
  flex-direction: row;
}

.mr-2 {
  margin-right: 2%;
}

.float-right {
  float: right;
}

.mt-2 {
  margin-top: 2%;
}

.mb-2 {
  margin-bottom: 2%;
}

.label {
  margin-left: 4%;
  font-weight: bold;
}

.modal-label {
  max-width: 100%;
  width: 100%;
  text-align: left;
  font-weight: bold;
}

.input-style {
  width: 90%;
  margin-bottom: 2%;
  margin-left: 4%;
  margin-top: 1%;
}

.link {
  margin-left: 4.5%;
  margin-top: 0.8%;
}

.uploaded-image {
  max-width: 60%;
  margin-bottom: 2%;
}

.edit-upload-btn {
  background: rgb(241, 98, 98);
  color: white;
  margin-top: 2%;
}

.trash-icon {
  background: rgb(241, 98, 98);
  font-size: 0.6rem;
  color: white;
  margin-top: 2%;
  margin-left: 2%;
}

.w-60 {
  width: 60%;
}

.text-center {
  text-align: center;
}

.font-6 {
  font-size: 0.6rem;
}

.error-link {
  color: red;
  text-align: center;
}

.image-link {
  text-align: center;
  margin: auto;
}

.image-link img {
  width: 20%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.upload-btn {
  text-align: center;
  margin: auto;
  display: block;
}

.w-100 {
  width: 100%;
  max-width: 100%;
}

.mt-10 {
  margin-top: 10px;
}

.alert-margin {
  margin: 10px 0;
}

.add-link-modal {
  display: block;
  text-align: center;
  font-weight: bold;
}

.height-style {
  opacity: 0.1;
  max-width: 100%;
  width: 100%;
}

.remove-btn {
  background: rgb(241, 98, 98);
  font-size: 0.6rem;
  color: white;
  margin-right: 2%;
  /* float: right; */
}

.bold-link {
  font-weight: bold;
  margin-left: 4.5%;
}

.edit-image-link {
  width: 60%;
  height: 160px;
  /* margin-bottom: 3%; */
}

.image-link-div {
  margin-top: -8%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.spin-integration {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.social-account {
  display: grid;
  grid-template-columns: 15% 15% 15% 15% 15% 15%;
  text-align: center;
  padding: 3% 2%;
  grid-row-gap: 10%;
  grid-gap: 2%;
}

.social-icon-btn {
  width: max-content;
  margin: 0;
  padding: 1px;
  border: none;
  font-size: 1.5rem;
}

.icon-key-h5 {
  color: #414141;
  text-align: center;
  margin-top: 3%;
  margin-bottom: 3%;
  font-weight: bold;
}

.icon-key-p {
  color: #414141;
  text-align: center;
  margin-top: 25px;
  padding: 0 4%;
  word-wrap: break-word;
}

.w-80 {
  width: 80%;
}

.social-edit-btn {
  background: black;
  color: white;
  font-size: 0.6rem;
  margin-top: 3%;
  text-align: center;
}

.mt-25px {
  margin-top: 25px;
}

.social-cancel-btn {
  color: white;
  background-color: rgb(241, 98, 98);
  font-size: 0.6rem;
  margin: 2%;
  border: none;
  padding: 4px 15px;
  border-radius: 6px;
  height: 32px;
}

.social-save-btn {
  color: white;
  background-color: black;
  font-size: 0.6rem;
  margin: 2%;
  /* border: none; */
  padding: 4px 15px;
  border-radius: 6px;
}
@media screen and (max-width: 365px){
  .social-save-btn {
    height: 32px !important;
  }
}
.link-input {
  width: 80%;
  margin-top: 3%;
}

.mt-2rem {
  margin-top: 2rem;
}

.SocialLinksIcon {
  display: grid;
  grid-template-columns: repeat(6, 15%);
  text-align: center;
  padding-bottom: 4%;
  grid-gap: 2%;
  grid-row-gap: 10%;
  padding-top: 3%;
  padding-left: 3%;
}

.account-integration {
  text-align: end;
}

@media screen and (max-width: 600px) {
  .editUploadButton {
    margin-top: 2%;
  }

  /* 
  .confirmation-dialog {
    margin-top: 15% !important;
  } */

  .image-link-div {
    margin-top: 0;
  }

  .bold-link {
    font-weight: bold;
    margin-left: 0%;
  }

  .link {
    margin-left: 0%;
    margin-top: 0.8%;
  }

  .SocialLinksIcon {
    padding: 3% 2% 5% 2%
  }
}

@media (max-width: 991px) {
  .account-image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .remove-btn {
    margin-right: 6%;
  }
}

@media (min-width: 1780px) {
  .account-image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.profile-edit-button {
  display: none;
}

.edit-icon {
  display: none;
  cursor: pointer;
}

.edit-icon-configure {
  /* display: block; */
  cursor: pointer;
}

.edit-link-wrapper:hover .edit-icon {
  display: inline-block;
  font-size: 1.25rem;
}


.creator-profile-social-links .edit-link-wrapper:hover .profile-links-button {
  display: none !important;
}

.creator-profile-social-links .edit-link-wrapper:hover .profile-edit-button {
  display: block !important;
}

.available-profile-icon {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-wrap: wrap;
  column-gap: 15px;
  /* box-shadow: none; */
  margin-top: 4%;
}