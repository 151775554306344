.copy-clipboard-btn-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(250, 250, 250);
	gap: 10px;
	border-radius: 8px;
	padding: 2px 6px;
	border: dashed rgb(154, 154, 154) 1px;
	cursor: pointer;
}

.follower-share-icon {
	box-shadow: 0px 0px 8px -5px rgb(0 0 0);
    background: #fff;
    border: none;
    padding: 12px 12px;
    border-radius: 50%;
}

.tooltip-style {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.m-0{
	margin: 0;
}
