/* Primary Add Button - Higher importance in UI hierarchy */
.primary-add-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #111111;
  color: white;
  border: none;
  border-radius: 30px;
  padding: 14px 28px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  height: 48px;
  letter-spacing: 0.5px;
  margin: 20px auto 24px;
  position: relative;
  overflow: hidden;
  min-width: 220px;
}

.primary-add-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%);
  border-radius: 30px;
  opacity: 0.3;
  transition: opacity 0.25s ease;
  pointer-events: none;
}

.primary-add-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border-radius: 30px;
  opacity: 0;
  transition: opacity 0.25s ease;
  pointer-events: none;
}

.primary-add-button:hover {
  background-color: #000000;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.25);
  transform: translateY(-3px);
}

.primary-add-button:hover::after {
  opacity: 0.15;
  background-color: white;
}

.primary-add-button:active {
  transform: translateY(-1px);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  background-color: #000000;
}

.primary-add-icon {
  font-size: 16px;
  margin-right: 10px;
  transition: transform 0.3s ease;
}

.primary-add-button:hover .primary-add-icon {
  transform: rotate(90deg) scale(1.1);
}

.primary-add-text {
  transform: translateY(0.5px);
  position: relative;
}

@media (max-width: 768px) {
  .primary-add-button {
    padding: 12px 22px;
    font-size: 14px;
    height: 44px;
    min-width: 180px;
    margin: 16px auto 20px;
  }
  
  .primary-add-icon {
    font-size: 15px;
    margin-right: 8px;
  }
}

.component-box {
  touch-action: manipulation;
  -webkit-overflow-scrolling: touch;
  position: relative;
  transition: transform 0.2s ease;
}

.component-box.dragging {
  background: #ffffff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transform: scale(1.02);
  z-index: 999;
}
/* 
@media (max-width: 768px) {
  .component-box {
    margin: 8px 0;
    user-select: none;
    -webkit-user-select: none;
  }

  .component-box.dragging {
    transform: scale(1.01);
  }

  .outline-drag {
    padding: 12px;
    margin: -12px;
  }
}  */